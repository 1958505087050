import React from "react";
import {fetchFeedbacks} from "../../services";
import loader_img from '../../../assets/ajax-loader.gif'


class FeedbacksBlock extends React.Component {
  state = {
    quest: false,
    questType: false,
    feedbacksData: [],
    page: 1,
    loading: false,
    hasMorePages: true
  };

  constructor() {
    super();
    this.jsPhrases = window.js_phrases
  }

  componentDidMount = () => {
    this.loadFeedbacksPage(1)
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noimageindex';
    document.head.appendChild(metaRobots);
  };

  loadFeedbacksPage = (page) => {
    const {quest, tag, questtype} = this.props;

    this.setState({quest: quest, questType: questtype})

    const limit = parseInt(this.props.limit);  // можно переделать в пагинацию по этому числу
    this.setState({loading: true});
    fetchFeedbacks(quest, tag, page, questtype).then(response => {
      this.setState({quest})
      if (response.feedbacksData.length === 0) {
        this.setState({hasMorePages: false})
      } else {
        let data = response.feedbacksData;
        if (limit) {
          data = data.slice(0, limit)
        }
        const newData = this.state.feedbacksData.concat(data);
        this.setState({feedbacksData: newData, page: response.page, loading: false})
      }
    })
  };
  loadNextPage = () => {
    if (this.state.loading) {
      return false
    }
    const page = this.state.page + 1;
    this.setState({page: page});
    this.loadFeedbacksPage(page)
  };

  fillFeedbackCommentDangerously(dangerousComment) {
    return {__html: dangerousComment};
  };

  renderFeedback = (feedback, index) => {
    const {quest, questType, feedbacksData} = this.state

    // Если Valencia, то не рендерим значок экспертности и рендерим &nbsp;
    const renderImg = (feedbackIndex) => {
      const cityId = parseInt(feedbacksData[feedbackIndex]['quest_location_city_id'], 10)
      if (cityId !== 85) {
        return (
          <img
            alt={feedback.expert_title}
            style={{width: "20px", margin: "0 5px"}}
            src={feedback.icon_url}
          />
        )
      }
      return ' '
    }

    return (
      <div className="feedback" key={feedback.id}>
        <div className="feedback__title">
          <img className="feedback__avatar" src={feedback.avatar}/>
          <div className="feedback__description">
            <h3 className="feedback__name">
              {feedback.nickname}
              {renderImg(index)}
              {feedback.expert_title}
            </h3>
            {/*<h4 className="feedback__expert__title">{feedback.expert_title }</h4>*/}
            <h4 className="feedback__games__count">{feedback.games_completed}</h4>
            <h4 className="feedback__date">{this.jsPhrases["feedbacks.game_date"]}: {feedback.date}</h4>

          </div>
        </div>
        <div className="feedback__content">
          <div dangerouslySetInnerHTML={this.fillFeedbackCommentDangerously(feedback.comment)} className="feedback__text"/>
        </div>

        {feedback.response_text ?
            <div className="feedback__content response">
              {/* Ответ Клаустрофобии TODO: провалидировать данные через dangerous */}
              <p className="feedback__text"><b>
                <span className="response__check_mark">✔ </span>
                <span className="response__title">{this.jsPhrases["feedbacks.response"]}</span>
              </b></p>
              <p className="feedback__text">{feedback.response_text}</p>
            </div>
            : "" }

        <div className="feedback__quest">
          <div className="feedback__quest__caption">{this.jsPhrases["feedbacks.quest_feedbacks"]}:</div>
          <div className="feedback__quest__title">
            {questType ?
              (<a className="feedback__quest__link" href={feedback.quest_link}>
                <span className="feedback__quest__title-name">«{feedback.quest}»
                  <span className="feedback__quest__title-name-arrow"/>
                </span>
              </a>)
              :
              (<span className="feedback__quest__title-name">«{feedback.quest}»</span>)
            }
          </div>
        </div>
      </div>
    )
  };
  renderMoreLink = () => {
    if (this.state.hasMorePages) {
      if (this.props.morelink) {
        return (<a className="feedbacks-more__button" style={{'cursor': 'pointer', 'color': 'black'}}
                   href={this.props.morelink}>
          {this.state.loading ?
            <img width="10px" height="10px" src={loader_img}/> : this.jsPhrases["feedbacks.more_feedbacks"]}</a>)
      } else {
        return (<a className="feedbacks-more__button" style={{'cursor': 'pointer', 'color': 'black'}}
                   onClick={this.loadNextPage}>
          {this.state.loading ?
            <img width="10px" height="10px" src={loader_img}/> : this.jsPhrases["feedbacks.more_feedbacks"]}</a>)
      }
    }
  };

  render = () => {
    const feedbacks = this.state.feedbacksData;
    return (
      <div>
        <div id="feedbacks-container">
          {feedbacks.map((feedback, index) => this.renderFeedback(feedback, index))}
        </div>
        <div className="feedbacks-more">
          {this.renderMoreLink()}
        </div>
      </div>
    )
  };
}

export default FeedbacksBlock;
