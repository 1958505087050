import React, { Component } from "react";
import "../../../sass/authFormStyles.sass";

import ReactPhoneInput from "react-phone-input-2";
import { register, fetchUserInfo } from "../../services";
import PhoneVerificationForm from "../common/PhoneVerificationForm";

export default class RegistrationForm extends Component {
  state = {
    currentStep: "form",
    formData: {
      reg_subscribed_to_newsletter: true,
      user_agreement: false,
    },
    response: {},
    errors: {},
    phone: "",
    phone_cc: ""
  };

  changeFormStep = newStep => {
    this.setState({ currentStep: newStep });
  };

  changeCurrentStepTo = newForm => () => {
    this.props.changeCurrentStepTo(newForm);
  };

  onCloseBtnClick = () => {
    this.props.onClose();
  };

  onFormItemChange = target => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [target.name]: target.value }
    });
  };

  onSubscribeCheckboxChange = () => {
    const checked = this.state.formData.reg_subscribed_to_newsletter;
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        reg_subscribed_to_newsletter: !checked
      }
    });
  };

  onUserAgreementCheckboxChange = () => {
    const checked = this.state.formData.user_agreement;
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        user_agreement: !checked
      }
    });
  };

  onPhoneChange = (value, data) => {
    // сохраняем введенное в поле значение
    this.setState({ phone: value });
    if (Object.keys(data).length) {
      const phone_cc = data.dialCode;
      this.setState({ phone_cc });
    }
  };

  sendMindBoxRegistrationEvent = (registrationData) => {
    if (mindbox !== undefined) {
      let data = {
        operation: "Online.RegistrationOnWebsite.JS",
        data: {
          customer: {
            lastName: "",
            firstName: registrationData.name,
            middleName: "",
            email: registrationData.email,
            customFields: {
              cITY: registrationData.city_name
            },
            subscriptions: [
              {
                pointOfContact: "Email",
                valueByDefault: true,
                isSubscribed: this.state.formData.reg_subscribed_to_newsletter
              }
            ]
          },
        },
      }
      window.directCrm('async', data);
    }
  }

  onFormSubmit = event => {
    event.preventDefault();
    const cleanPhone = this.state.phone
      .slice(1 + this.state.phone_cc.length)
      .replace(/\D+/g, "");
    const data = {
      ...this.state.formData,
      reg_phone_cc: this.state.phone_cc,
      reg_phone: cleanPhone,
      reg_utm_params: window.location.search,
    };
    register(data).then(response => {
      if (response.status === "ok") {
        window.dataLayer.push({ event: "RegStep1" });
        this.setState({ response });

        fetchUserInfo().then(userInfo => {
          this.sendMindBoxRegistrationEvent(userInfo);
        });

        this.changeFormStep("phone");
      } else {
        this.setState({ errors: response.errors });
      }
    });
  };

  onCodeSubmitCallback = () => {
    // если регистрация в процессе бронирования,
    // то после подтверждения номера выполняем коллбэк функцию
    if (this.props.authCallback) {
      this.props.authCallback();
    }
    // если регистрация из меню, переадресуем пльзователя в его новый личный кабинет
    else window.location = this.state.response.user_profile_url;
  };

  renderPhoneVerificationForm = () => {
    return (
      <PhoneVerificationForm
        onCodeSubmitCallback={this.onCodeSubmitCallback}
        phone={this.state.phone}
        jsPhrases={this.props.jsPhrases}
        user_id={this.state.response.user_id}
        userInfo={this.props.userInfo}
      />
    );
  };

  renderAgreementsForValencia = (city_id, jsPhrases) => {
    if (city_id === 85) {
     return (
      <div>
        {jsPhrases["popup.registry.booking_agreement_on"]}{" "}
        <a
          href="/es/aviso_legal/"
          className="inline-link in-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          {jsPhrases["popup.registry.booking_agreement_personal_data"]}{" "}
        </a>
        {jsPhrases["popup.registry.booking_agreement_and_accept_terms"]}{" "}
      </div>
    )} else {
      return (
        <div>
          {jsPhrases["popup.registry.booking_agreement"]}{" "}
        </div>
      )
    }
  }

  renderCheckbox = () => {
    let {
      jsPhrases,
      userInfo: { city_id },
    } = this.props;
    // Если Таллин или Валенсия, то клиент сам ставит галочку в форме
    // city_id = 85; // для дебага
    if (city_id === 13 || city_id === 85) {
      return (
        <label
          className={`checkbox-label${
            this.state.formData.user_agreement ? " active" : ""
          }`}
        >
          <div className="info-text">
            {this.renderAgreementsForValencia(city_id, jsPhrases)}
            <a
              href={city_id === 85 ? "/es/CONDITIONS/" : "/ru/user-agreement/"}
              className="inline-link in-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              {jsPhrases["popup.registry.user_agreement"]}
            </a>
          </div>
          <input
            type="checkbox"
            className="form-checkbox"
            onChange={this.onUserAgreementCheckboxChange}
            name="user_agreement"
            checked={this.state.formData.user_agreement}
          />
        </label>
      );
    } else {
      return (
        <div className="info-text">
          <div className="info-icon" />
          {jsPhrases["popup.registry.booking_agreement"]}{" "}
          <a
            href="/ru/user-agreement/"
            className="inline-link in-text"
          >
            {jsPhrases["popup.registry.user_agreement"]}
          </a>
        </div>
      );
    }
  };

  renderRegistrationForm = () => {
    const {
      jsPhrases,
      userInfo: { country_code, city_id }
    } = this.props;
    const { errors } = this.state.errors;
    return (
      <div className="popup-pane auth-pane">
        <div className="popup-close-button" onClick={this.onCloseBtnClick} />
        <div className="form-title">
          <div className="form-title-icon pen" />
          <div className="form-title-text">
            {jsPhrases["popup.registry.title"]}
          </div>
        </div>
        <form onSubmit={this.onFormSubmit} action="POST">
          <label className="input-label">
            <div>{jsPhrases["popup.registry.name"]} *</div>
            <input
              type="text"
              className="form-text-input auth-text-input "
              placeholder={jsPhrases["popup.registry.type_your_name"]}
              onChange={event => this.onFormItemChange(event.target)}
              name="reg_username"
              autoComplete="username"
              required
            />
            {this.renderErrorField("reg_username")}
          </label>
          <label className="input-label">
            <div>Email *</div>
            <input
              type="email"
              className="form-text-input auth-text-input  "
              placeholder={jsPhrases["popup.registry.enter_email"]}
              onChange={event => this.onFormItemChange(event.target)}
              name="reg_email"
              required
            />
            {this.renderErrorField("reg_email")}
          </label>
          <label className="phone-input-label" name="reg_phone">
            <div>{jsPhrases["popup.registry.phone"]} *</div>
            {this.renderErrorField("reg_phone")}
          </label>
          <ReactPhoneInput
            preferredCountries={["ru"]}
            value={this.state.phone}
            defaultCountry={country_code}
            enableSearchField={true}
            countryCodeEditable={false}
            onChange={(value, data) => this.onPhoneChange(value, data)}
            placeholder={jsPhrases["popup.registry.type_phone"]}
            inputExtraProps={{
              name: "reg_phone",
              required: true,
              minLength: 4
            }}
            inputClass="phone-input"
            dropdownClass="phone-dropdown"
          />
          <label className="input-label">
            <div>{jsPhrases["popup.registry.password"]} *</div>
            <input
              type="password"
              className="form-text-input auth-text-input "
              placeholder={jsPhrases["popup.registry.type_your_password"]}
              onChange={event => this.onFormItemChange(event.target)}
              name="reg_password"
              required
            />

            {this.renderErrorField("reg_password")}
          </label>
          {this.renderCheckbox()}
          <label
            className={`checkbox-label${
              this.state.formData.reg_subscribed_to_newsletter ? " active" : ""
            }`}
          >
            <div className="info-text">
              {jsPhrases["popup.registry.informational_messages_agreement"]}
            </div>
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={this.onSubscribeCheckboxChange}
              name="reg_subscribed_to_newsletter"
              checked={this.state.formData.reg_subscribed_to_newsletter}
            />
          </label>
          <button
            className="form-button submit"
            disabled={[13, 85].includes(city_id) ? !this.state.formData.user_agreement : false}
          >
            {jsPhrases["popup.registry.register"]}
          </button>
        </form>
        <div
          className="relocation-link"
          onClick={this.changeCurrentStepTo("auth")}
        >
          {jsPhrases["popup.registry.already_have_an_account"]}
        </div>
      </div>
    );
  };

  renderErrorField = fieldName => {
    if (Object.keys(this.state.errors).includes(fieldName))
      return (
        <div className={`form-field-error`}>{this.state.errors[fieldName]}</div>
      );
    else return "";
  };

  render() {
    return this.state.currentStep === "form"
      ? this.renderRegistrationForm()
      : this.renderPhoneVerificationForm();
  }
}
