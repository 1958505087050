export const VK_APP_CONFIG = {
  client_id: 6628481,
  client_secret: "EUG3BlLbOmpwlPrXaeKz",
  redirect_uri: "http://claustrophobia.com/socials/vk/auth",
  auth_scope: "friends,offline,email"
};

export function getVkAuthorizeLink(timeslotId = "") {
  let redirect_uri;
  if (timeslotId)
    redirect_uri = `${VK_APP_CONFIG.redirect_uri}?book=${timeslotId}`;
  else redirect_uri = VK_APP_CONFIG.redirect_uri;

  const link = `https://oauth.vk.com/authorize?client_id=${
    VK_APP_CONFIG.client_id
  }&display=popup&scope=${
    VK_APP_CONFIG.auth_scope
  }&response_type=code&v=5.80&redirect_uri=${redirect_uri}`;

  return link;
}
