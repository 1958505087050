import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../../sass/bookingFormStyles.sass";
import BookingPane from "./BookingPane";
import MaskedInput from "react-text-mask";

import { fetchUserInfo } from "../../services";
import {
  fetchTicketTypesData,
  postTicketsData,
  postPromocode
} from "../../services/challengeRoomsServices";
import { renderLockers, debounce } from "../../utils";

class FormWithTicketTypes extends Component {
  state = {
    ticketsData: {},
    questInfo: {},
    chosenTickets: {},
    email: "",
    phone: "",
    name: "",
    currentPrice: 0,
    userInfo: {},
    userIsLoaded: false,
    ticketsDataIsLoaded: false,
    error: "",
    discountPercent: 0,
    promocode: "",
    gtmData: {}
  };

  componentDidMount = () => {
    fetchUserInfo().then(userInfo => {
      this.setState({ userInfo, userIsLoaded: true });
      if (!userInfo.anonymous)
        this.setState({
          email: userInfo.email,
          phone: userInfo.phone,
          name: userInfo.name
        });
    });
    fetchTicketTypesData(this.props.questId).then(data => {
      this.setState({
        ticketsData: data.ticket_types,
        questInfo: data.quest,
        ticketsDataIsLoaded: true
      });
      // set default selected tickets count value
      for (let ticketType of this.state.ticketsData){
        const { chosenTickets } = this.state;
        let data = {
          ...chosenTickets,
          [ticketType.id]: ticketType.default_value
        };
        this.onTicketsChange(data)
      }
    });
  };

  getNumberWithCurrencySign = num => (
    //только рубли
    <span
      dangerouslySetInnerHTML={{
        __html: num + "&nbsp" + "&#8381;"
      }}
    />
  );

  onTicketsChange = newTicketsInfo => {
    this.setState({ chosenTickets: newTicketsInfo, error: "" });
  };
  updateGtmData = gtmData => {
    this.setState({ gtmData: gtmData});
  };

  decreaseTickets = id => {
    const { chosenTickets, ticketsData, gtmData } = this.state;
    const ticketsCount = chosenTickets[id] || 0;
    const ticketInfo = ticketsData.find(el => el.id == id);
    if (ticketsCount > 0) {
      this.onTicketsChange({
        ...chosenTickets,
        [id]: ticketsCount - 1
      });
      this.updateGtmData({
        ...gtmData,
        [id]: {
          ...ticketInfo,
          quantity: ticketsCount - 1,
        }
      })
    }
  };

  increaseTickets = id => {
    const { chosenTickets, ticketsData, gtmData } = this.state;
    const ticketInfo = ticketsData.find(el => el.id == id);
    const ticketsCount = chosenTickets[id] || 0;
    if (ticketsCount < ticketInfo.maximum) {
      this.onTicketsChange({
        ...chosenTickets,
        [id]: ticketsCount + 1
      });
      this.updateGtmData({
        ...gtmData,
        [id]: {
          ...ticketInfo,
          quantity: ticketsCount + 1,
        }
      })
    }
  };

  toggleTicketType = id => {
    const { chosenTickets } = this.state;
    if (chosenTickets[id] > 0) {
      this.onTicketsChange({ ...chosenTickets, [id]: 0 });
    } else {
      this.increaseTickets(id);
    }
  };

  renderTicketsCounters = array => {
    const { chosenTickets, questInfo } = this.state;
    const { jsPhrases } = this.props;
    return array.map(el => (
      <React.Fragment key={el.id}>
        <div className={`service-option`}>
          <div
            className={`checkbox-label ${
              chosenTickets[el.id] > 0 ? " active" : ""
            }`}
            onClick={() => this.toggleTicketType(el.id)}
          >
            {el.name}
            <div className="booking-option-accompanying-text">
              {el.description && el.description}
            </div>
            <span>{this.getNumberWithCurrencySign(el.price)} { el.sales_unit ? `/ ${el.sales_unit}` : null }</span>
          </div>
          <div className="service-counter">
            <div
              className="counter-button decrease"
              onClick={() => this.decreaseTickets(el.id)}
            />
            <div className="current-services-amount">
              {chosenTickets[el.id] || 0}
            </div>
            <div
              className="counter-button increase"
              onClick={() => this.increaseTickets(el.id)}
            />
          </div>
        </div>
      </React.Fragment>
    ));
  };

  renderQuestInfo = () => {
    const {
      questInfo: {
        logo_url,
        name,
        complexity_in_locks,
        gamers_count,
        adult_content,
        available_languages
      }
    } = this.state;

    const langArray = available_languages.map(l => l.code);

    return (
      <React.Fragment>
        <div>
          <img className="quest-logo" alt="quest-logo" src={logo_url} />
        </div>
        <div className="quest-title">{name}</div>
        {/* <div className="quest-time">
          {date}, {time}
        </div> */}
        <div className="quest-info">
          {renderLockers(complexity_in_locks)}
          <div className="players-count">{gamers_count}</div>
          <div className="adult-content">
            {adult_content ? `${adult_content}+` : ""}
          </div>
          <div>
            {langArray.map(l => (
              <div className="lang-tag" key={`lang-tag-${l}`}>
                {l}
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderFormFields = () => {
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit} className="ticket-type-form-fields">
          <label className="input-label">
            <div>Имя *</div>
            <input
              type="text"
              className="form-text-input auth-text-input"
              placeholder={"Введите имя"}
              onChange={({ target: { value } }) =>
                this.setState({ name: value, error: "" })
              }
              value={this.state.name}
              name="name"
              autoComplete="name"
              required
            />
          </label>

          <label className="input-label">
            <div>E-mail *</div>
            <input
              type="email"
              className="form-text-input auth-text-input"
              placeholder={"Введите email"}
              onChange={({ target: { value } }) =>
                this.setState({ email: value, error: "" })
              }
              value={this.state.email}
              name="email"
              autoComplete="email"
              required
            />
          </label>
          <label className="input-label">
            <div>Телефон *</div>
            <MaskedInput
              type="text"
              pattern=".{17,}"
              required
              guide={false}
              className="form-text-input auth-text-input"
              placeholder={"Введите телефон"}
              onChange={({ target: { value } }) =>
                this.setState({ phone: value, error: "" })
              }
              value={this.state.phone}
              name="phone"
              autoComplete="phone"
              mask={[
                /\d/,
                " ",
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/
              ]}
            />
          </label>
          <button
            type="submit"
            className={`form-button submit booking-submit`}
            onClick={() => {
              this.onSubmit();
            }}
          >
            Оплатить
            {/* jsPhrases["popup.booking.payment.pay"] */}
          </button>
        </form>
      </React.Fragment>
    );
  };

  getCurrentPrice = () => {
    const { chosenTickets, discountPercent } = this.state;
    const fullPrice = Object.keys(chosenTickets).reduce((acc, ticketTypeId) => {
      const ticketsTypeInfo = this.state.ticketsData.find(
        ticketsType => ticketsType.id == ticketTypeId
      );
      const ticketsPrice = chosenTickets[ticketTypeId] * ticketsTypeInfo.price;
      return acc + ticketsPrice;
    }, 0);
    if (discountPercent) return fullPrice - fullPrice * discountPercent;
    return fullPrice;
  };

  renderPromoInput = () => {
    const { discountPercent, promocode } = this.state;
    const onChange = debounce(value => this.onPromoSubmit(value), 1000);
    return (
      <label className="input-label">
        <div>Промокод</div>
        <input
          className={`form-text-input form-promo ${
            discountPercent ? "promo-valid" : promocode ? "promo-invalid" : ""
          }`}
          readOnly={Boolean(discountPercent)}
          onChange={({ target: { value } }) => onChange(value)}
        />
      </label>
    );
  };

  onPromoSubmit = value => {
    if (this.state.discountPercent) return;
    postPromocode(value).then(response => {
      this.setState({ promocode: value });
      if (response.valid) {
        this.setState({
          discountPercent: Number(response.discount_percent) / 100
        });
      }
    });
  };
  analyticsDataPush = (gtmData) => {
    const products = Object.values(gtmData).map((item) => {
      return {
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: item.quantity
      }
    });

    // window.dataLayer.push({
    //   event: "checkout",
    //   ecommerce: {
    //     checkout: {
    //       actionField: { step: 1, option: "kassa" },  // hardcoded payment option
    //       products: products
    //     }
    //   }
    // });
    window.dataLayer.push({
      event: "addToCart",
          ecommerce: {
            currency_code: 'RUB', // Hardcoded currency code
            add: { products: products }
          }
    });

  }
  onSubmit = e => {
    e.preventDefault();
    this.setState({ error: "" });
    const {
      chosenTickets,
      email,
      phone,
      name,
      promocode,
      discountPercent,
      gtmData,
    } = this.state;
    const ticketsData = Object.keys(chosenTickets).reduce(
      (acc, ticketTypeId) => {
        return chosenTickets[ticketTypeId]
          ? [
              ...acc,
              {
                ticket_type: ticketTypeId,
                quantity: chosenTickets[ticketTypeId]
              }
            ]
          : acc;
      },
      []
    );
    const data = {
      tickets: ticketsData,
      email,
      phone,
      name,
      promocode: discountPercent ? promocode : ""
    };
    this.analyticsDataPush(gtmData);

    if (!(phone && email && name)) {
      this.setState({ error: "Все поля обязательны для заполнения" });
    } else if (!ticketsData.length) {
      this.setState({ error: "Выберите билеты" });
    } else {
      postTicketsData(data).then(response => {
        if (response.redirect_url) window.location = response.redirect_url;
        else this.setState({ error: response.msg });
      });
    }
  };

  render() {
    const {
      error,
      ticketsData,
      userIsLoaded,
      ticketsDataIsLoaded,
      discountPercent
    } = this.state;

    const keyIsShown = Boolean(!userIsLoaded || !ticketsDataIsLoaded);

    if (keyIsShown)
      return (
        <BookingPane>
          <div className="preloader-image" />
        </BookingPane>
      );
    return (
      <BookingPane>
        {this.renderQuestInfo()}
        <div className="additional-services-wrapper">
          <div className="services-options-wrapper">
            {this.renderTicketsCounters(ticketsData)}
          </div>
        </div>
        {this.renderPromoInput()}
        <div className="current-price">
          {this.getNumberWithCurrencySign(this.getCurrentPrice())}
          {discountPercent ? (
            <div className="current-price-info">
              С учетом скидки {discountPercent * 100}%
            </div>
          ) : (
            ""
          )}
        </div>
        {this.renderFormFields()}
        {error ? <div className={`response-error-block`}>{error}</div> : ""}
      </BookingPane>
    );
  }
}

FormWithTicketTypes.propTypes = {
  jsPhrases: PropTypes.object.isRequired,
  questId: PropTypes.string.isRequired
};

export default FormWithTicketTypes;
