import React, { Component } from "react";
import "./select.sass";

const Select = ({ options, className, ...props }) => {
  return (
    <select
      {...props}
      className={`search-input search-select ${className ? className : ""}`}
      required
      defaultValue=""
    >
      {options.map(({ value, name }, i) => (
        <option
          className="search-select-option"
          value={value}
          key={name}
          diasabled={(i === 0).toString()}
        >
          {name}
        </option>
      ))}
    </select>
  );
};

export default Select;
