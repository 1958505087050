import React from "react";
import PropTypes from "prop-types";
import PassRecoveryForm from "./PassRecoveryForm";
import AuthForm from "./AuthForm";
import RegistrationForm from "./RegistrationForm";
import "../../../sass/authFormStyles.sass";
import "../../../sass/commonStyles.sass";
import { fetchUserInfo } from "../../services";
import ReactDOM from "react-dom";

const forms = {
  auth: AuthForm,
  passRecovery: PassRecoveryForm,
  registration: RegistrationForm
};

class AuthFormBlock extends React.Component {
  state = {
    currentForm: "auth",
    userInfo: {}
  };

  componentDidMount = () => {
    //если прокинут юзер сверху - пишем его в state
    //если не прокинут - запрашиваем
    //в случае, если пришел зареганый - переадресация в профиль
    //а если анонимный - показываем форму регистрации
    if (this.props.userInfo) this.setState({ userInfo: this.props.userInfo });
    else
      fetchUserInfo().then(userInfo => {
        if (!userInfo.anonymous) window.location = "/profile";
        this.setState({ userInfo });
      });
  };

  changeCurrentStepTo = newForm => {
    this.setState({ currentForm: newForm });
  };

  // onClose = () => {};

  onCloseBtnClick = () => {
    ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode);
    this.props.onClose();
    // this.onClose;
  };

  render() {
    const Form = forms[this.state.currentForm];
    return (
      <React.Fragment>
        <div className="popup-background">
          <div className="popup-content">
            <Form
              timeslotId={this.props.timeslotId || ""}
              userInfo={this.state.userInfo}
              changeCurrentStepTo={this.changeCurrentStepTo}
              onClose={this.onCloseBtnClick}
              authCallback={this.props.authCallback}
              jsPhrases={this.props.jsPhrases}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AuthFormBlock.propTypes = {
  userInfo: PropTypes.object,
  authCallback: PropTypes.func,
  jsPhrases: PropTypes.object
};

export default AuthFormBlock;
