import React, { Component } from "react";
import PropTypes from "prop-types";

import PhoneVerificationForm from "../common/PhoneVerificationForm";

import { login } from "../../services";
import { getCsrf } from "../../services/csrf";
import { getVkAuthorizeLink } from "../../services/vkAuth";
import { loginViaFacebook } from "../../services/fbAuth";
// import "../../../sass/commonStyles.sass";
import "../../../sass/authFormStyles.sass";

export default class AuthForm extends Component {
  state = {
    currentStep: "form",
    userId: "",
    phone: "",
    formData: {},
    errors: {},
    loading: false
  };

  onCloseBtnClick = () => {
    this.props.onClose();
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const data = this.state.formData;
    login(data).then(response => {
      if (response.status === "error") {
        this.setState({ errors: response.errors });
        this.setState({ loading: false });
      } else if (response.status === "ok") {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: "MailEnter" });
        this.authCallback();
      } else {
        this.setState({ errors: {login: 'Произошла ошибка'} });
        this.setState({ loading: false });
      }
    });
  };

  onFormItemChange = target => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [target.name]: target.value }
    });
  };

  authCallback = () => {
    if (this.props.authCallback) this.props.authCallback();
    else {
      window.location.href.includes('santa') ? window.location.reload() : window.location = "/profile";
    }
  };

  changeCurrentStepTo = newForm => () => {
    this.props.changeCurrentStepTo(newForm);
  };

  vkAuth = () => {
    const { timeslotId } = this.props;
    const link = getVkAuthorizeLink(timeslotId);
    window.dataLayer.push({ event: "VKEnter" });
    window.location.href.includes('santa') ? window.location.reload() : window.location = link;
  };

  fbAuth = () => {
    const loginCallback = response => {
      let formData = new FormData();
      formData.append("accessToken", response["authResponse"]["accessToken"]);
      formData.append("expiresIn", response["authResponse"]["expiresIn"]);
      formData.append(
        "signedRequest",
        response["authResponse"]["signedRequest"]
      );
      formData.append("userID", response["authResponse"]["userID"]);

      return fetch(`/api/core/v1/login_via_facebook/`, {
        method: "POST",
        headers: {
          "X-CSRFToken": getCsrf()
        },
        body: formData
      })
        .then(response => response.json())
        .then(res => {
          window.dataLayer.push({ event: "FBEnter" });

          this.setState({ userId: res.user_id, phone: res.phone });
          if (res.requires_phone_confirmation) {
            this.setState({ currentStep: "phone" });
          } else {
            this.authCallback();
          }
        });
    };

    loginViaFacebook(loginCallback);
  };

  renderAuthForm = () => {
    const { jsPhrases, userInfo, timeslotId } = this.props;
    const { loading } = this.state;
    return (
      <div className="popup-pane auth-pane">
        <div className="popup-close-button" onClick={this.onCloseBtnClick} />
        <div className="form-title">
          <div className="form-title-icon auth" />
          <div className="form-title-text">
            {jsPhrases["popup.login.title"]}
          </div>
        </div>
        {timeslotId ? (
          <div className="info-text">
            <div className="info-icon" />
            {jsPhrases["popup.login.auth_for_booking"]}
          </div>
        ) : (
          ""
        )}


        <div className="divider-wrapper">
          <div className="accompanying-text">
            {jsPhrases["popup.login.auth_by"]}
          </div>
        </div>

        {userInfo.vk_link ? (
          <div className="socials-wrapper">
            {userInfo.vk_link && (
              <div className="social-auth vk" onClick={this.vkAuth}>
                <div className="social-icon" />
                <div className="social-text">{jsPhrases["popup.login.vk"]}</div>
              </div>
            )}
            {/*Пока что комментирую, а не удаляю, потому что есть вероятность возвращения FB*/}
            {/*<div className="social-auth fb" onClick={this.fbAuth}>*/}
            {/*  <div className="social-icon" />*/}
            {/*  <div className="social-text">facebook</div>*/}
            {/*</div>*/}
          </div>
        ) : null }

        {userInfo.vk_link ? (
          <div className="divider-wrapper">
            <div className="accompanying-text">{jsPhrases["popup.login.or"]}</div>
          </div>
        ) : null }

        <form onSubmit={this.onFormSubmit} action="POST">
          {this.state.errors.login && (
            <div>
              <div className={`response-error-block`}>
                {this.state.errors.login}
              </div>
            </div>
          )}
          <label className="input-label">
            <div>{jsPhrases["popup.login.login"]} *</div>
            <input
              type="text"
              className="form-text-input auth-text-input"
              placeholder={jsPhrases["popup.login.type_your_login"]}
              onChange={event => this.onFormItemChange(event.target)}
              name="login"
              autoComplete="username"
              required
            />
          </label>
          <label className="input-label">
            <div>{jsPhrases["popup.login.password"]} *</div>
            <input
              type="password"
              className="form-text-input auth-text-input"
              placeholder={jsPhrases["popup.login.type_your_password"]}
              onChange={event => this.onFormItemChange(event.target)}
              name="password"
              autoComplete="current-password"
              required
            />
          </label>
          <div
            className="inline-link"
            onClick={this.changeCurrentStepTo("passRecovery")}
          >
            {jsPhrases["popup.login.recovery_password"]}
          </div>
          <button
            className={`form-button submit${
              loading ? " form-button-disabled" : ""
            }`}
          >
            {jsPhrases["popup.login_btn_label"]}
          </button>
          {loading ? (
            <div className="preloader-wrapper auth-preloader-wrapper">
              <div className="preloader-image auth-preloader" />
            </div>
          ) : (
            ""
          )}
        </form>

        <div className="accompanying-text">
          {jsPhrases["popup.login.not_registered_yet"]}
        </div>
        <div
          className="relocation-link with-arrow"
          onClick={this.changeCurrentStepTo("registration")}
        >
          {jsPhrases["popup.login.create_account"]}
        </div>
      </div>
    );
  };

  renderPhoneVerificationForm = () => {
    return (
      <PhoneVerificationForm
        // onCodeChange={this.onFormItemChange}
        onCodeSubmitCallback={this.authCallback}
        jsPhrases={this.props.jsPhrases}
        user_id={this.state.userId}
        phone={this.state.phone}
        userInfo={this.props.userInfo}
      />
    );
  };

  render() {
    return this.state.currentStep === "form"
      ? this.renderAuthForm()
      : this.renderPhoneVerificationForm();
  }
}

AuthForm.propTypes = {
  timeslotId: PropTypes.string,
  jsPhrases: PropTypes.object,
  authCallback: PropTypes.func,
  changeCurrentStepTo: PropTypes.func,
  onClose: PropTypes.func,
  userInfo: PropTypes.object
};
