import React, { Component } from "react";
// import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Slider from "@material-ui/lab/Slider"
import Popup from "reactjs-popup";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import "./UserProfilePhotoUploader.sass";


class UserProfilePhotoUploader extends Component {

    state = {
        imageSrc: null,
        hideInput: false,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        croppedAreaPixels: null,
        croppedImage: null,
    };

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        this.setState({
            croppedAreaPixels,
        })
    }

    makeResult = async () => {
        console.log("Image uploading...");
        const croppedImg = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        )
        console.log(croppedImg);
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    onClose = () => {
        this.setState({
            croppedImage: null,
        })
    }

    onFileChange = async event => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            let imageDataUrl = await readFile(file)

            this.setState({
                imageSrc: imageDataUrl,
                crop: { x: 0, y: 0 },
                zoom: 1,
                hideInput: true,
            })
        }
    }

    componentDidMount() {
        // getUserBanners().then(userBannersList => this.sortUserBannersList(userBannersList))
    }

    render() {
        let { hideInput } = this.state
        let { jsPhrases } = this.props
        return (
            <div className="settings__block row photo_cropper">
                <div className="col-md-3">
                    <span>{jsPhrases["popup.settings.cropper.profile_photo"]}</span>
                </div>
                <div className="col-md-3">
                    <Popup trigger={<button className="button">{jsPhrases["popup.settings.cropper.upload_button"]}</button>} modal>
                        {close => (
                            <div className="modal_user_profile_photo">
                                <div className="popupContent">
                                    <h2>{jsPhrases["popup.settings.cropper.change_photo"]}</h2>
                                    {hideInput ?
                                        null
                                        :
                                        (
                                            <div>
                                                <label className="file-input" for="inputfile">
                                                    <CloudUploadIcon style={{ marginRight: "0.3em" }} />
                                                    {jsPhrases["popup.settings.cropper.upload_input"]}
                                                </label>
                                                <input type="file" id="inputfile" className="file-input" onChange={this.onFileChange} accept="image/jpeg" />
                                            </div>
                                        )
                                    }
                                    {this.state.imageSrc && (
                                        <React.Fragment>
                                            <div className="crop-container">
                                                <Cropper
                                                    image={this.state.imageSrc}
                                                    crop={this.state.crop}
                                                    zoom={this.state.zoom}
                                                    aspect={this.state.aspect}
                                                    onCropChange={this.onCropChange}
                                                    onCropComplete={this.onCropComplete}
                                                    onZoomChange={this.onZoomChange}
                                                />
                                            </div>
                                            <div className="controls">
                                                <Slider
                                                    value={this.state.zoom}
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    aria-labelledby="Zoom"
                                                    // event не удалять, иначе слайдер не работает
                                                    onChange={(event, zoom) => this.onZoomChange(zoom)}
                                                    classes={{ container: 'slider' }}
                                                />
                                            </div>
                                            <div className="actions">
                                                <button
                                                    className="button"
                                                    onClick={() => {
                                                        console.log("modal closed")
                                                        this.makeResult()
                                                        console.log("photo uploaded")
                                                        close();
                                                    }}
                                                >
                                                    {jsPhrases["popup.settings.cropper.save"]}
                                                </button>
                                                <button
                                                    className="button"
                                                    onClick={() => {
                                                        console.log("modal closed");
                                                        close();
                                                    }}
                                                >
                                                    {jsPhrases["popup.settings.cropper.close"]}
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
                <div
                    className="popup-text-uploaded"
                >
                    {this.state.imageSrc != null ? <p>Файл загружен!</p> : null}
                </div>
            </div>
        )
    };
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export default UserProfilePhotoUploader;
