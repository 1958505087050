import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "../common/Select";
import { getSearchOptions } from "../../services";

import "../../../sass/bookingFormStyles.sass";
import "./basicSearch.sass";

const capitalize = string => {
  if (string) return string[0].toUpperCase() + string.slice(1);
  else {
    return "";
  }
};

class BasicSearch extends Component {
  state = {
    searchOptions: { complexity: [], categories: [], players_amount: [] },
    formData: {},
    mode: 'invisible',
  };

  componentDidMount() {
    getSearchOptions().then(searchOptions => this.setState({ searchOptions }));
  }

  onFormChange = ({ name, value }) => {
    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };

  onSearchSubmit = () => {
    window.set_basic_search(
      this.state.formData,
      this.renderComplexityOptions(this.state.searchOptions.complexity)
    );
  };

  renderCategoriesOptions = categories =>
    categories.map(({ name, slug }) => ({ name, value: slug }));
  renderComplexityOptions = complexityArray =>
    complexityArray
      .map(({ name, id }) => ({
        name: capitalize(name),
        name_original: (name),
        value: id
      }));

  render() {
    const { complexity, categories, players_amount } = this.state.searchOptions;
    const { jsPhrases } = this.props;

    const categoriesOptions = [
      {
        value: "",
        // name: "Категория"
        name: jsPhrases["basic_search.category"]
      },
      ...this.renderCategoriesOptions(categories)
    ];
    const complexityOptions = [
      {
        value: "",
        // name: "Сложность"
        name: jsPhrases["basic_search.complexity"]
      },
      ...this.renderComplexityOptions(complexity)
    ];
    const playersAmountOptions = [
      {
        value: "",
        // name: "Кол-во игроков"
        name: jsPhrases["basic_search.players_amount"]
      },
      ...players_amount
    ];

    let { mode } = this.state;
    return (
      <div className={"basic-search-container" + ( " " + mode )}>
        <div className="basic-search">
          <div className="search-row">
            <div className="basic-search-block one-input-container">
              <Select
                className="search-select"
                type="select"
                name="category"
                options={categoriesOptions}
                onChange={({ target }) => this.onFormChange(target)}
              />
            </div>
            <div className="basic-search-block two-inputs-container">
              <Select
                className="complexity-search search-select"
                type="select"
                name="complexity"
                options={complexityOptions}
                onChange={({ target }) => this.onFormChange(target)}
              />
              <Select
                className="search-select"
                type="select"
                name="players"
                options={playersAmountOptions}
                onChange={({ target }) => this.onFormChange(target)}
              />
            </div>
            <div className="basic-search-block search-button-container">
              <a
                href="#search-result"
                className="form-button submit search-button"
                onClick={this.onSearchSubmit}
              >
                {/* Найти */}
                {jsPhrases["basic_search.find"]}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicSearch.propTypes = {
  jsPhrases: PropTypes.object.isRequired
};

export default BasicSearch;
