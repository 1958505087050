import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../../../sass/bookingFormStyles.sass";
import Select from "../common/Select";

class PlayersForm extends React.Component {
    state = {
        playersCount: this.props.initialPlayersCount,
        chosenAdditionalServices: {},
        activeLanguage: this.props.initialLanguage,
        modeName: "",
        mode: {},
        team_name: ""
    };

    componentDidUpdate = () => {
        const {
            timeslotData: {
                quest: {id: questId}
            }
        } = this.props;
        const {playersCount, chosenAdditionalServices} = this.state;
        //для home video от 5 игроков и SEX от 4 игроков - включается доп. актер, если не был включен ранее
        if (
            (questId === 748 && playersCount > 3) ||
            (questId === 857 && playersCount > 4)
        ) {
            const serviceId = "2";
            if (!chosenAdditionalServices[serviceId]) this.toggleService(serviceId);
        }
    };

    onPlayersAmountChange = newAmount => {
        this.setState({playersCount: newAmount});
    };

    onLanguageChange = newLanguage => {
        this.setState({activeLanguage: newLanguage});
    };

    onAdditionalServicesChange = newServices => {
        this.setState({chosenAdditionalServices: newServices});
    };

    getAdditionalServicesData = () => {
        const {chosenAdditionalServices} = this.state;
        return Object.keys(chosenAdditionalServices).reduce((acc, id) => {
            const count = chosenAdditionalServices[id];
            if (count > 0) return [...acc, {id, count}];
            else return [...acc];
        }, []);
    };

    additionalServicesPrice = chosenAdditionalServices => {
        const {additional_services: additionalServices} = this.props.timeslotData;
        return Object.keys(chosenAdditionalServices).reduce((acc, id) => {
            const serviceInfo = additionalServices.filter(el => el.id == id)[0];
            return acc + serviceInfo.price * chosenAdditionalServices[id];
        }, 0);
    };

    getPrice = playersCount => {
        const isModeChosen = !!this.state.modeName;
        return isModeChosen
            ? this.state.mode.prices_by_tickets_count.find(
                p => p.gamers_count == playersCount
            ).price
            : this.props.timeslotData.prices_by_tickets_count.find(
                p => p.gamers_count == playersCount
            ).price;
    };

    getPricePerPerson = (price, persons) => Math.ceil(price / persons);

    increasePlayersAmount = () => {
        const {
            timeslotData: {
                available_tickets,
                prices_by_tickets_count,
                quest: {
                    id: questId,
                    max_gamers: default_max_gamers,
                    quest_type: questType,
                    allow_multibooking: allow_multibooking
                }
            }
        } = this.props;
        const {playersCount, mode, modeName} = this.state;
        const modeIsChosen = !!modeName;

        // если Железяки - смотрим максимальное кол-во игроков в availiable_tickets,
        // иначе - в режиме или таймслоте
        const max_gamers =
            allow_multibooking
                ? available_tickets
                : modeIsChosen
                    ? mode.max_gamers
                    : default_max_gamers;

        // Если правил с ценами не хватает, не даем увеличивать
        //const ticketsLimit = Math.max(...prices_by_tickets_count.map((x)=>x.gamers_count));
        const priceAvailable = prices_by_tickets_count.filter((x) => x.gamers_count === playersCount + 1).length > 0;

        if (playersCount < max_gamers && priceAvailable) {
            this.onPlayersAmountChange(playersCount + 1);
        }
    };

    decreasePlayersAmount = () => {
        const {
            timeslotData: {
                prices_by_tickets_count,
                quest: {min_gamers: default_min_gamers}
            }
        } = this.props;
        const {playersCount, mode, modeName} = this.state;
        const modeIsChosen = !!modeName;
        const min_gamers = modeIsChosen ? mode.min_gamers : default_min_gamers;

        const priceAvailable = prices_by_tickets_count.filter((x) => x.gamers_count === playersCount - 1).length > 0;

        if (this.state.playersCount > min_gamers && priceAvailable) {
            this.onPlayersAmountChange(playersCount - 1);
        }
    };

    increaseAdditionalService = id => {
        const {additional_services: additionalServices} = this.props.timeslotData;
        const {chosenAdditionalServices} = this.state;
        const serviceInfo = additionalServices.filter(el => el.id == id)[0];
        const serviceCount = chosenAdditionalServices[id] || 0;
        if (serviceCount < serviceInfo.maximum) {
            this.onAdditionalServicesChange({
                ...chosenAdditionalServices,
                [id]: serviceCount + 1
            });
        }
    };

    decreaseAdditionalService = id => {
        const {chosenAdditionalServices} = this.state;
        const serviceCount = chosenAdditionalServices[id] || 0;
        if (serviceCount > 0) {
            this.onAdditionalServicesChange({
                ...chosenAdditionalServices,
                [id]: serviceCount - 1
            });
        }
    };
    renderLanguageButtons = array => {
        const {activeLanguage} = this.state;
        return array.map(el => (
            <div
                className={`booking-button ${
                    activeLanguage === el.code ? "pushed" : ""
                }`}
                title={el.name}
                onClick={() => this.onLanguageChange(el.code)}
                key={`lang-button-${el.code}`}
            >
                {el.code}
            </div>
        ));
    };

    toggleService = id => {
        const {chosenAdditionalServices} = this.state;
        if (chosenAdditionalServices[id] > 0) {
            this.onAdditionalServicesChange({...chosenAdditionalServices, [id]: 0});
        } else {
            this.increaseAdditionalService(id);
        }
    };

    renderModeSelector = modes => {
        if (!modes) return "";

        const {jsPhrases} = this.props;
        const getModeByName = modeName => {
            return modes.find(mode => mode.name === modeName) || {};
        };
        const setMode = newModeName => {
            const mode = getModeByName(newModeName);
            const newMaxGamers = newModeName
                ? mode.max_gamers
                : this.props.initialPlayersCount;
            this.setState({
                modeName: newModeName,
                mode,
                playersCount: newMaxGamers
            });
        };
        const renderOptions = modes => {
            const optionsArray = modes.map(({name}) => ({
                name: name,
                value: name
            }));
            return [
                {
                    name: jsPhrases["popup.booking.players.default_mode"],
                    value: "default"
                },
                ...optionsArray
            ];
        };
        const currentMode = this.state.mode;
        const isModeChosen = !!this.state.modeName;

        return (
            <div>
                <div className="divider-wrapper empty-divider"/>
                <div className="modes-wrapper">
                    <div className="modes-info">
                        <div>
                            {/* Режим игры */}
                            {jsPhrases["popup.booking.players.game_mode"]}
                        </div>
                        <div className="booking-option-accompanying-text">
                            {/* Выберите режим */}
                            {jsPhrases["popup.booking.players.choose_game_mode"]}
                        </div>
                    </div>
                    <div className="modes-select-wrapper">
                        <Select
                            name="modes"
                            className="modes-selector"
                            options={renderOptions(modes)}
                            onChange={({target: {value}}) =>
                                value === "default" ? setMode("") : setMode(value)
                            }
                        />
                    </div>
                </div>
                {isModeChosen && currentMode.description ? (
                    <div className="info-text modes-info-text">
                        <div className="info-icon"/>
                        {currentMode.description}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    renderServicesButtons = array => {
        const {chosenAdditionalServices} = this.state;
        return array.map(el => {
            if (el.multiple)
                return (
                    <div key={el.id} className={`service-option`}>
                        <div
                            className={`checkbox-label ${
                                chosenAdditionalServices[el.id] > 0 ? " active" : ""
                            }`}
                            onClick={() => this.toggleService(el.id)}
                        >
                            {el.name}
                            {el.price > 0 ? <span style={{whiteSpace: "nowrap"}}> – {el.price} ₽</span> : ""}
                            <div className="booking-option-accompanying-text">
                                {el.description && el.description}
                            </div>
                        </div>
                        <div className="service-counter">
                            <div
                                className="counter-button decrease"
                                onClick={() => this.decreaseAdditionalService(el.id)}
                            />
                            <div className="current-services-amount">
                                {chosenAdditionalServices[el.id] || 0}
                            </div>
                            <div
                                className="counter-button increase"
                                onClick={() => this.increaseAdditionalService(el.id)}
                            />
                        </div>
                    </div>
                );
        });
    };

    onExtraFieldChange = val => {
        this.setState({teamName: val});
    };

    onSubmit = e => {
        if (e.target.classList.contains("disabled")) {
            return false;
        }
        const {onSubmit, onAnonymousSubmit, userInfo, timeslotData} = this.props;
        const {playersCount, activeLanguage, mode, teamName} = this.state;

        e.target.classList.add("disabled");

        const data = {
            tickets_count: playersCount,
            timeslot: timeslotData.id,
            language_code: activeLanguage,
            additional_services: this.getAdditionalServicesData(),
            mode: mode.id || false,
            team_name: teamName,
        };
        userInfo.phone && userInfo.is_trusted_email ? onSubmit(data) : onAnonymousSubmit(data);
    };

    render = () => {
        const {
            timeslotData: {
                price,
                prices_by_tickets_count,
                quest: {max_gamers: default_max_gamers, available_languages, quest_type},
                modes,
                additional_services: additionalServices
            },
            getNumberWithCurrencySign,
            jsPhrases
        } = this.props;

        const isModeChosen = !!this.state.modeName;
        let max_gamers = isModeChosen
            ? this.state.mode.max_gamers
            : default_max_gamers;

        const {chosenAdditionalServices, playersCount} = this.state;

        const isPriceDynamic =
            !!prices_by_tickets_count && !!prices_by_tickets_count.length;
        const currentPrice =
            (isPriceDynamic ? this.getPrice(playersCount) : price) +
            this.additionalServicesPrice(chosenAdditionalServices);

        const extraFields = quest_type === 'quiz';
        if (extraFields) { // Пока будем брать макс кол-во из билетки
            max_gamers = Math.max(...prices_by_tickets_count.map((x) => x.gamers_count))
        }

        return (
            <React.Fragment>
                <div className="players-counter-wrapper">
                    <div className="players-info">
                        <div>
                            {/* Игроков */}
                            {jsPhrases["popup.booking.players.players"]}
                        </div>
                        <div className="booking-option-accompanying-text">
                            {/* Свободно */}
                            {jsPhrases["popup.booking.players.free"]}
                            {` ${this.props.timeslotData.available_tickets} `}
                            {/* из */}
                            {jsPhrases["popup.booking.players.out_of"]}
                            {` ${max_gamers}`}
                        </div>
                    </div>
                    <div className="players-counter">
                        <div
                            className="counter-button decrease"
                            onClick={this.decreasePlayersAmount}
                        />

                        {/*If the number of available tickets is less than one, we block the possibility of placing an order.*/}
                        {this.props.timeslotData.available_tickets > 0 ?
                            (
                                <div className="current-players-amount">{`${playersCount}`}</div>
                            ) :
                            (
                                <div className="current-players-amount">{`${this.props.timeslotData.available_tickets}`}</div>
                            )
                        }

                        <div
                            className="counter-button increase"
                            onClick={this.increasePlayersAmount}
                        />
                    </div>
                </div>
                {available_languages.length ? (
                    <React.Fragment>
                        <div className="divider-wrapper empty-divider"/>
                        <div className="language-wrapper">
                            <div className="language-info">
                                <div>
                                    {/* Язык игры */}
                                    {jsPhrases["popup.booking.players.game_language"]}
                                </div>
                                <div className="booking-option-accompanying-text">
                                    {/* Выберите язык */}
                                    {jsPhrases["popup.booking.players.choose_language"]}
                                </div>
                            </div>
                            <div className="buttons-wrapper">
                                {this.renderLanguageButtons(available_languages)}
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    ""
                )}
                {modes ? this.renderModeSelector(modes) : ""}
                {additionalServices.length ? (
                    <div className="additional-services-wrapper">
                        <div className="divider-wrapper">
                            <div className="accompanying-text">
                                {jsPhrases["popup.booking.players.additional_services"]}
                            </div>
                        </div>
                        <div className="services-options-wrapper">
                            {this.renderServicesButtons(additionalServices)}
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {extraFields ? (
                    <div className="extra-fields-wrapper">
                        <label className="input-label promocode-booking-label">
                            <input
                                className={`form-text-input form-promo`}
                                placeholder={
                                    // `Промокод`
                                    jsPhrases["popup.booking.players.team_name"]
                                }
                                onChange={event => {
                                    this.onExtraFieldChange(event.target.value);
                                }}
                            />
                        </label>
                    </div>
                ) : (
                    ""
                )}

                {/*If the number of available tickets is less than one, we block the possibility of placing an order.*/}
                {this.props.timeslotData.available_tickets > 0 ? (
                    <React.Fragment>
                        <div className="current-price">
                            {getNumberWithCurrencySign(currentPrice)}
                        </div>

                        <div>
                            ~{" "}
                            {getNumberWithCurrencySign(
                                this.getPricePerPerson(currentPrice, playersCount)
                            )}
                            {` / `}
                            {jsPhrases["popup.booking.players.person"]}
                        </div>
                        <div className="taxes-comment">
                            {/* Налоги включены в стоимость */}
                            {jsPhrases["popup.booking.players.taxes"]}
                        </div>


                        <div className="form-button next-step" onClick={e => this.onSubmit(e)}>
                            {/* Далее */}
                            {jsPhrases["popup.booking.players.next"]}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="current-price">
                            {/* Нет свободных билетов */}
                            {jsPhrases["popup.booking.players.no_free"]}
                        </div>
                    </React.Fragment>
                )
                }

            </React.Fragment>
        );
    };
}

PlayersForm.propTypes = {
    timeslotData: PropTypes.object.isRequired,
    jsPhrases: PropTypes.object.isRequired,
    getNumberWithCurrencySign: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
    initialLanguage: PropTypes.string.isRequired,
    initialPlayersCount: PropTypes.number.isRequired
};

export default PlayersForm;
