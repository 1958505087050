import {getCsrf} from './csrf';

export function fetchRecommendedGames(quest_id, mode='default') {
  return fetch(`/api/recommendations/?quest_id=${quest_id}&mode=${mode}`).then(function(
    response
  ) {
    return response.json();
  });
}

export function fetchUserInfo() {
  return fetch("/api/user-info/", {
    cache: "no-store",
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer"
  }).then(response => response.json());
}

export function fetchTimeslotData(timeslotId) {
  return fetch(`/api/timeslot_booking/?timeslot_id=${timeslotId}`,{
    headers: { 
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrf()
      },
   }).then(
    response => response.json()
  );
}

export function fetchTimeslotDataWithLang(timeslotId, languageCode) {

  return fetch(`/api/timeslot_booking/?timeslot_id=${timeslotId}&lang=${languageCode}`,
    {headers: {
      'Accept-Language': languageCode, 
      "X-CSRFToken": getCsrf()}
    }).then(
    response => response.json()
  );
}

export function login(data) {
  return fetch(`/api/login`, {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function register(data) {
  return fetch(`/api/core/v1/registration/`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function sendPhone(data) {
  /*
    :params:
      {
        'user': user_id,
        'phone': phone
      } 
    :return: JSON
  */
  return fetch(`/api/resend_code`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

const parseCookie = str =>
  str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});

export function confirmPhone(data) {
  return fetch(`/api/confirm_phone`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": parseCookie(document.cookie)["phobia_csrf_token"],
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function sendPasswordRecoveryEmail(data) {
  return fetch(`/api/recover`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function book(data) {
  /*
    :params:
      {
        'timeslot': timeslot_id,
        'promocode': promocode,
        'tickets_count': tickets_count,
      } 
    :return: JSON
  */

  return fetch(`/api/timeslot_booking/`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function embedBook(timeslotId, token, languageCode, data) {

  let embedBookFormData = new FormData();

  for ( var key in data ) {
    embedBookFormData.append(key, data[key]);
  }

  return fetch(`/api/v1/external/booking/${timeslotId}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Accept-Language": languageCode,
      "Authorization": `Token ${token}`,
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: embedBookFormData
  }).then(response => response.json());
}

export function getSearchOptions() {
  return fetch(`/api/v1/search-filters`, {
    cache: "no-store",
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer"
  }).then(response => response.json());
}

export function getUserBanners(bannerRequestReferrerPath='unknown') {
  let fetchUrl = `/api/banners?referrerPath=${bannerRequestReferrerPath}`
  return fetch(fetchUrl, {
    cache: "no-store",
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer"
  }).then(response => response.json());
}

export function fetchFeedbacks(questId, tagSlug, page, questType) {
  return fetch(`/api/feedbacks/?quest_id=${questId}&tag=${tagSlug}&page=${page}&quest_type=${questType}`, {
    cache: "no-store",
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer"
  }).then(response => response.json());
}

export function sendEmailVerificationRequest(userID, email) {
  // Был добавлен csrf-token для того, чтобы была пройдена проверка при
  // отправке почты пользователем, когда он бронирует квест, если is_trusted_email = False
  return fetch(`/api/user_profile/${userID}`, {
    cache: "no-store",
    method: "PUT",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify({'email': email})
  }).then(response => response.json());
}

export function pushUserProfilePhoto(photo) {
  let data = new FormData();
  data.append('image', photo, 'avatar.jpeg');

  return fetch('/api/post-user-profile-photo/', {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: "same-origin",
    headers: {
      "X-CSRFToken": getCsrf() 
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: data
  }).then(response => response.json());
}