import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Timer extends Component {
  state = {
    sec: 0,
    min: 0,
    secRemaining: 0
  };

  componentDidMount = () => {
    const { secRemaining } = this.props;
    const min = Math.floor(this.secRemaining / 60);
    const sec = secRemaining - min * 60;
    this.setState({ min, sec, secRemaining });
    this.startCountDown();
  };

  tick = () => {
    const { secRemaining } = this.state;
    const min = Math.floor(this.state.secRemaining / 60);
    const sec = secRemaining - min * 60;
    this.setState({ min, sec, secRemaining: secRemaining - 1 });
  };

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 1000);
  };

  render = () => {
    const { min, sec, secRemaining } = this.state;
    const timerString = `${min < 10 ? "0" : ""}${min}:${
      sec < 10 ? "0" : ""
    }${sec}`;
    return (
      <span>{secRemaining >= 0 && (min || sec) ? timerString : "00:00"}</span>
    );
  };
}

Timer.propTypes = {
  secRemaining: PropTypes.number
};
