import {getCsrf} from './csrf';


export function pay(data) {
  /* :params {
            'game': game_id,
            'payment_method': payment method code ex: YANDEX_AC,
        }: */

  return fetch(`/pay/api/`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export const getYandexKassaToken = (ymShopId, data) => {
  //testYMShopId = 613986
  const checkout = window.YooMoneyCheckout(613986, {
    language: "ru"
  });
  const { number, cvc, month, year } = data;
  return checkout.tokenize({ number, cvc, month, year });
};

export function useCertificate(data) {
  /* Оплата игры сертификатом
        :params:
        {
            'game_id': int Required,
            'certificate_serial': int Required,
            'certificate_code': str Required,
        } */

  return fetch(`/api/usecertificate/`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json())
}

export function applyPromocode(data) {
  return fetch(`/api/applypromocode/`, {
    method: "POST", 
    mode: "cors",   
    cache: "no-cache",   
    credentials: "same-origin",   
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",   
    referrer: "no-referrer",   
    body: JSON.stringify(data)   
  }).then(response => response.json());
}

