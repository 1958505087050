import React from "react";

export function cleanFirstAndLastSpaces(str) {
  if (!str.match(/\s$/) && !str.match(/^\s/)) return str;
  if (str.match(/\s$/)) return cleanFirstAndLastSpaces(str.slice(0, -1));
  if (str.match(/^\s/)) return cleanFirstAndLastSpaces(str.slice(1));
}

export function isEqual(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function(item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
}

export function getAllGenresOptions() {
  var cards = $(".quest-card");
  var genres = new Set();
  var slugs = new Set();
  cards.each(function(i, card) {
    var genresString = $(card).attr("data-tags-names");
    var genresArray = genresString.split(",");
    genresArray.forEach(genreName => {
      if (genreName) genres.add(genreName);
    });

    var genresSlugs = $(card).attr("data-tags-slug");
    var slugsArray = genresSlugs.split(", ");
    slugsArray.forEach(slug => {
      if (slug) slugs.add(slug);
    });
  });
}

export function getAllComplexityOptions() {
  var cards = $(".quest-card");
  var complexityOptions = new Set();
  cards.each(function(i, card) {
    var complexity = $(card).attr("data-complexity");
    if (complexity && complexity !== "None") {
      complexityOptions.add(complexity);
    }
  });
}

export function renderLockers(valueArray) {
  return (
    <div className="complexity-locks">
      {valueArray.map((value, i) => (
        <div className={`complexity-lock ${value}`} key={`locker-${i}`} />
      ))}
    </div>
  );
}

export function debounce(func, delay) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}
