import ReactHabitat from "react-habitat";
import BookingForm from "./components/BookingForm";
import EmbedBookingForm from "./components/EmbedBookingForm";
import FormWithTicketTypes from "./components/BookingForm/FormWithTicketTypes";
import AuthFormBlock from "./components/AuthFormBlock";
import Carousel from "./components/Carousel";
import BasicSearch from "./components/BasicSearch";
import TopBannerBlock from "./components/TopBannerBlock";
import FeedbacksBlock from "./components/Feedbacks";
import EmailVerificationForm from "./components/common/EmailVerificationForm";
import UserProfilePhotoUploader from "./components/UserProfilePhotoUploader/UserProfilePhotoUploader";

// Define an array of containers with the HTML ID's to attach to
const containers = [
  {
    id: "booking-form",
    component: BookingForm
  },
  {
    id: "embed-booking-form",
    component: EmbedBookingForm
  },
  {
    id: "auth-form-block",
    component: AuthFormBlock
  },
  {
    id: "recommended-carousel",
    component: Carousel
  },
  {
    id: "basic-search",
    component: BasicSearch
  },
  {
    id: "form-with-ticket-type",
    component: FormWithTicketTypes
  },
  {
    id: "promo-banner",
    component: TopBannerBlock
  },
  {
    id: "section-feedbacks-with-loader",
    component: FeedbacksBlock
  },
  {
    id: "brotherhood-email-verification-form",
    component: EmailVerificationForm
  },
  {
    id: "user-profile-photo-uploader",
    component: UserProfilePhotoUploader
  },
];

class MyApp extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    // Create a new container builder:
    const builder = new ReactHabitat.ContainerBuilder();

    // Register container components
    for (let container of containers) {
      builder.register(container.component).as(container.id);
    }

    // Finally, set the container:
    this.setContainer(builder.build());
    window.updateHabitat = this.update.bind(this);
  }
}

// Always export a 'new' instance so it immediately evokes:
export default new MyApp();


