import React, {Component} from "react";
import {embedBook, fetchTimeslotDataWithLang} from "../../services";
// import PropTypes from "prop-types";
import "./EmbedBookingForm.sass";


class EmbedBookingForm extends Component {

    state = {
        timeslot: {},
        timeslotIsLoaded: false,
        timeslotIsBooked: false,

        clientName: false,
        clientEmail: false,
        clientPhone: false,

        bookingTicketsCount: 4,
        bookingPromocode: '',
        bookingComment: '',
        // bookingIsValidated: false,
        bookingPrice: false,
        bookingPriceCurrency: false,
        bookingShouldBePayed: false,
        bookingSourceToken: '41d7b81bc8e99bcee9bc5dbcc7058358a0026e2b',
        bookingRequestResponseMessage: false,
        bookingPaymentLink: false,
        bookingLanguage: false,
        legalCheck: 'off',

        // We block the submit button if the request is being processed
        requestIsBeingProcessed: false,
    };

    componentDidMount() {
        const timeslotId = this.props.timeslotId

        const forcedLocale = this.props.forcedLocale || 'ru';

        forcedLocale ? this.setState({bookingLanguage: forcedLocale}) : null

        fetchTimeslotDataWithLang(timeslotId, forcedLocale).then(timeslot => {
            this.setState({
                timeslot,
                timeslotIsLoaded: true,
                timeslotIsBooked: false,
                bookingPriceCurrency: timeslot.currency_sign,
                bookingShouldBePayed: timeslot.pp,
            });
            this.getInitialPrice(timeslot)
        });
    }

    getInitialPrice(timeslot) {
        let timeslotPriceGrid = timeslot.prices_by_tickets_count

        let lowest = Number.POSITIVE_INFINITY;
        let tmp;

        for (var i = timeslotPriceGrid.length - 1; i >= 0; i--) {
            tmp = timeslotPriceGrid[i].gamers_count;
            if (tmp < lowest) lowest = tmp;
        } // Overriding highest tickets_count value from quest in case of using common price grids:

        if (lowest < timeslot.quest.min_gamers) {
            lowest = timeslot.quest.min_gamers;
        }

        let timeslotPrice = timeslotPriceGrid.find(x => x.gamers_count === lowest);

        this.setState({
            bookingPrice: timeslotPrice.price,
            bookingTicketsCount: timeslotPrice.gamers_count
        })
    }

    handleTicketsDecrease(event) {
        event.preventDefault()
        let {bookingTicketsCount} = this.state
        this.handleTicketsCountChange(bookingTicketsCount - 1)
    }

    handleTicketsIncrease(event) {
        event.preventDefault()
        let {bookingTicketsCount} = this.state
        this.handleTicketsCountChange(bookingTicketsCount + 1)
    }

    handleTicketsCountChange(tickets_count) {

        let {timeslot} = this.state
        let timeslotPriceGrid = timeslot.prices_by_tickets_count
        let timeslotPrice = timeslotPriceGrid.find(x => x.gamers_count === tickets_count);


        if (timeslotPrice && timeslot.quest.min_gamers <= tickets_count && timeslot.quest.max_gamers >= tickets_count) {
            this.setState({bookingTicketsCount: tickets_count, bookingPrice: timeslotPrice.price})
        }
    }

    renderTicketsCounter(timeslot) {
        let {bookingTicketsCount} = this.state
        return (
            <div className="cf-tickets_counter">
                <a onClick={e => this.handleTicketsDecrease(e)} className="cf-round-button decrease" href="#/">-</a>
                <span className="cf-tickets-current">{bookingTicketsCount}</span>
                <a onClick={e => this.handleTicketsIncrease(e)} className="cf-round-button increase" href="#/">+</a>
            </div>
        )
    }

    renderBookingPrice() {
        let {bookingPrice, bookingPriceCurrency} = this.state
        return (
            <p className="cf-booking-price">
                {bookingPrice}
                {" "}
                <span dangerouslySetInnerHTML={{__html: `${bookingPriceCurrency}`}}></span>
            </p>
        )
    }

    renderBookingPromt(timeslot) {

        const {jsPhrases} = this.props;

        const targetTimeslot = this.state.timeslot
        const timeslotQuest = targetTimeslot.quest || false

        let {bookingLanguage} = this.state
        const isLegalAcceptRequired = bookingLanguage == 'es'

        let {clientName, clientEmail, clientPhone, legalCheck} = this.state

        let isPreValidated = false
        if (clientName && clientEmail && clientPhone) {
            let clientNameIsValidated = false
            if (clientName.length > 2) {
                clientNameIsValidated = true
            }

            let clientEmailIsValidated = false
            const emailValidationRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (clientEmail.length > 2 && emailValidationRe.test(String(clientEmail).toLowerCase())) {
                clientEmailIsValidated = true
            }

            let clientPhoneIsValidated = false
            if (clientPhone.length > 6) {
                clientPhoneIsValidated = true
            }

            let legalAcceptIsValidated;
            if (!isLegalAcceptRequired) {
                legalAcceptIsValidated = true
            } else legalAcceptIsValidated = isLegalAcceptRequired && legalCheck === 'on';

            if (clientNameIsValidated && clientEmailIsValidated && clientPhoneIsValidated && legalAcceptIsValidated) {
                isPreValidated = true
            }
        }

        const requestIsBeingProcessed = this.state.requestIsBeingProcessed

        return (
            <form id="aggregators-booking-form" className="cf-booking-form">
                <label className="cf-label" htmlFor="cf-name">
                    {jsPhrases["popup.registry.name"]}
                    <input className="cf-input" onChange={e => this.setState({clientName: e.target.value})} type="text"
                           id="cf-name"/>
                </label>
                <label className="cf-label" htmlFor="cf-email">
                    {jsPhrases["popup.registry.email"]}
                    <input className="cf-input" onChange={e => this.setState({clientEmail: e.target.value})}
                           type="email" id="cf-email"/>
                </label>
                <label className="cf-label" htmlFor="cf-phone">
                    {jsPhrases["popup.registry.phone"]}
                    <input className="cf-input" onChange={e => this.setState({clientPhone: e.target.value})} type="tel"
                           id="cf-phone"/>
                </label>
                <label className="cf-label" htmlFor="cf-promocode">
                    {jsPhrases["popup.booking.payment.promo_placeholder"]}
                    <input className="cf-input" onChange={e => this.setState({bookingPromocode: e.target.value})}
                           type="text" id="cf-promocode"/>
                </label>
                <label className="cf-label" htmlFor="cf-comment">
                    {jsPhrases["crm.fields.comment"]}
                    <input className="cf-input" onChange={e => this.setState({bookingComment: e.target.value})}
                           type="text" id="cf-comment"/>
                </label>
                <label className="cf-label" htmlFor="cf-tickets_count">
                    <div>
                        {jsPhrases["popup.booking.players.tickets_amount"]}
                        {this.renderTicketsCounter(timeslot)}
                    </div>
                </label>

                {this.renderBookingPrice()}

                {isPreValidated ? (
                    <input className="cf-input" id="cf-submit-button" onClick={e => this.handleBooking(e)}
                           type="submit"
                           value={requestIsBeingProcessed ? "⏳" : jsPhrases["popup.booking.book_action"]}
                           disabled={requestIsBeingProcessed}
                    />
                ) : (
                    <input className="cf-input" id="cf-submit-button"
                           type="submit"
                           value={jsPhrases["popup.booking.book_action"]}
                           disabled/>
                )}

                { isLegalAcceptRequired ? (
                    <label htmlFor="cf-legal-check">
                        <input type="checkbox" id="cf-legal-check" style={{ margin: '1em', }} onChange={e => this.setState({legalCheck: e.target.value})}></input>
                        He leido y acepto
                        <a href="https://escapistas-valencia.com/aviso-legal"
                           style={{color: '#ffd500', fontSize: '1rem', marginLeft: '0.5rem'}}
                           target="_blank">los términos del acuerdo de usuario
                        </a>
                    </label>
                ) : null }

                {/*Аннотация с прямой ссылкой на формы брони слота на сайте*/}
                {/*{ bookingLanguage !== 'es' ? (*/}
                {/*  <p className="cf-booking-annotation">*/}
                {/*    {jsPhrases["popup.booking.book_on_site"]} {" "}*/}
                {/*    <a target="_blank" href={"https://claustrophobia.com" + timeslot.order_url}>claustrophobia.com</a>*/}
                {/*  </p>*/}
                {/*) : null}*/}
            </form>
        )
    }

    handleBooking(event) {
        event.preventDefault()

        this.setState({requestIsBeingProcessed: true})

        let bookingData = {
            name: this.state.clientName,
            email: this.state.clientEmail,
            phone: this.state.clientPhone,
            promocode: this.state.bookingPromocode,
            comment: this.state.bookingComment,
            tickets_count: this.state.bookingTicketsCount.toString(),
        }
        embedBook(this.state.timeslot.id, this.state.bookingSourceToken, this.state.bookingLanguage, bookingData).then(response => {
            this.setState({bookingRequestResponseMessage: response.msg})
            if (response.game_id) {
                this.setState({timeslotIsBooked: true})
            }
            if (response.payment_link) {
                this.setState({bookingPaymentLink: response.payment_link})
            }
        });
    }

    handleClose(event) {
        event.preventDefault()

        try {

            var parent = window.parent;
            parent.postMessage("cfbf-closed", "*");
        } catch (e) {
            console.error(e);
        }
    }

    renderBookingResponse() {
        let {bookingRequestResponseMessage, bookingPaymentLink, bookingShouldBePayed} = this.state
        const {jsPhrases} = this.props;

        return (
            <div className="cf-server-response">
                <span>{bookingRequestResponseMessage}</span>
                {bookingPaymentLink && bookingShouldBePayed ? (<p>
                    <a style={{backgroundColor: '#ffd500', color: "black", fontSize: '1.5rem', padding: "0.3rem 1.5rem", textDecoration: "none", borderRadius: "1rem"}}
                       target="_blank" {...bookingPaymentLink ? {href: bookingPaymentLink} : {}}>{jsPhrases["popup.booking.booking_payment_link"]}</a>
                </p>) : null}
            </div>
        )
    }

    render() {


        let {timeslot, timeslotIsLoaded, timeslotIsBooked, bookingRequestResponseMessage} = this.state

        const targetTimeslot = this.state.timeslot
        const timeslotQuest = targetTimeslot.quest || false
        const timeslotQuestName = timeslotQuest.name || "No name"
        let timeslotVerboseTime = targetTimeslot.start_date + ' ' + targetTimeslot.tm24

        if (timeslotQuest.id == 793) {
            timeslotVerboseTime = ""
        }

        if (timeslotQuest.id == 792) {
            timeslotVerboseTime = ""
        }

        return (
            <div className="claustrophobia-fullscreen-wrapper">
                {timeslotIsLoaded ? (
                    <div className="embed-booking-panel">
                        {/*<span id="cf-close-button" onClick={e => this.handleClose(e)} className="cf-close-button"></span>*/}

                        <h3 className="upper-title"> {timeslotVerboseTime}</h3>

                        <h2> {timeslotQuestName}</h2>
                        {bookingRequestResponseMessage ? this.renderBookingResponse() : null}
                        {timeslotIsBooked ? null : (this.renderBookingPromt(timeslot))}
                    </div>
                ) : null
                }
            </div>
        )
    }
}

export default EmbedBookingForm;
