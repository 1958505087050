import React, { Component } from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { useCertificate } from "../../services/payment";

class CertificateForm extends Component {
  state = { formData: {}, errors: {}, isPaymentLoading: false };

  onFormItemChange = ({ name, value }) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [name]: value }
    });
  };

  onSubmit = () => {
    const { certificate_serial, certificate_code } = this.state.formData;
    const { gameId, jsPhrases, analyticsDataPush } = this.props;
    if (!(certificate_serial && certificate_code))
      return this.setState({
        errors: { msg: jsPhrases["popup.booking.payment.required_fields"] }
      });
    this.setState({ isPaymentLoading: true, errors: {} });
    
    useCertificate({
      game_id: gameId,
      certificate_serial: Number(certificate_serial),
      certificate_code
    }).then(res => {
      if (!res.error) {
        analyticsDataPush();
        window.location = res.redirect_to;
      } else if (res.form_errors) {
        this.setState({ errors: res.form_errors, isPaymentLoading: false });
      } else {
        this.setState({
          errors: { paymentError: res.msg },
          isPaymentLoading: false
        });
      }
    });
  };

  render() {
    const { jsPhrases, bookingAccept } = this.props;
    return (
      <React.Fragment>
        <form className="payment-form-fields">
          <label className="input-label certificate-number mobile-block">
            <div>
              {/* Номер сертификата */}
              {jsPhrases["popup.booking.payment.cert_number"]}
              {` *`}
            </div>
            <MaskedInput
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/
              ]}
              guide={false}
              type="text"
              className="form-text-input"
              onChange={event => this.onFormItemChange(event.target)}
              name="certificate_serial"
              required
            />
          </label>
          <label className="input-label certificate-code mobile-block">
            <div>
              {/* Код сертификата */}
              {jsPhrases["popup.booking.payment.cert_code"]}
              {` *`}
            </div>
            <MaskedInput
              mask={[
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                " ",
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                " ",
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                " ",
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/,
                /[A-Za-z0-9]/
              ]}
              placeholderChar="_"
              placeholder="____ ____ ____ ____"
              type="text"
              className="form-text-input"
              onChange={event => this.onFormItemChange(event.target)}
              name="certificate_code"
              required
            />
          </label>
        </form>
        <div>
          {this.state.errors.certificate_serial && (
            <div className={`response-error-block`}>
              {this.state.errors.certificate_serial}
            </div>
          )}
          {this.state.errors.certificate_code && (
            <div className={`response-error-block`}>
              {this.state.errors.certificate_code}
            </div>
          )}
          {this.state.errors.msg && (
            <div className={`response-error-block`}>
              {this.state.errors.msg}
            </div>
          )}
        </div>

        {this.state.errors.paymentError ? (
          <div className="response-error-block">
            {this.state.errors.paymentError}
          </div>
        ) : (
          ""
        )}

        <button
          className={`form-button submit booking-submit${
            this.state.isPaymentLoading ? " form-button-disabled" : ""
          }`}
          onClick={this.onSubmit}
          disabled={!bookingAccept}
        >
          {/* Применить сертификат */}
          {jsPhrases["popup.booking.payment.use_cert"]}
        </button>
        {this.state.isPaymentLoading && (
          <div className="preloader-image payment-preloader" />
        )}
      </React.Fragment>
    );
  }
}

CertificateForm.propTypes = {
  jsPhrases: PropTypes.object.isRequired,
  gameId: PropTypes.number.isRequired,
  bookingAccept: PropTypes.bool.isRequired
};

export default CertificateForm;
