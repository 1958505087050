import React from "react";
import "../../../sass/bookingFormStyles.sass";

const BookingPane = props => {
  return (
    <div className="popup-background">
      <div className="popup-content">
        <div className="popup-pane booking-pane">
          <div className="popup-close-button" />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default BookingPane
