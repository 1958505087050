import React from "react";
import Slider from "react-slick";
import SliderArrow from "./SliderArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ScrollerBlock.sass";

const sliderDefaultSettings = {
  dots: true,
  arrows: true,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: true,
  autoplay: true,
  autoplaySpeed: 7000,
  initialSlide: 1,
  prevArrow: <SliderArrow to="prev" />,
  nextArrow: <SliderArrow to="next" />
};

class ScrollerBlock extends React.Component {

  render = () => {
    const {elements} = this.props;
    const settings = this.props.settings || sliderDefaultSettings;
    return (
      <Slider {...settings}>
        {elements}
      </Slider>
    )
  };
}

export default ScrollerBlock;
