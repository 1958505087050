import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

import { fetchRecommendedGames } from "../../services";
import SliderArrow from "./SliderArrow";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RecommendWrapper = styled.div`
  margin: 1rem 0
`;

const RecommendContainer = styled.div`
  padding: 0 10px;
  width: 100%;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    padding: 0 10px;
    width: 100%;
  }
`;

const RecommendTitle = styled.h4`
  text-align: center;
  margin-bottom: 20px;
`;

const WrapperWithoutSlider = styled.div`
  text-align: center;
  margin: 30px;
  & a {
    margin: 0 22px 0;
  }
`;

// обертка слайдеров
const SliderWrapper = styled.div`
  width: 1328px;
  margin: 30px auto 0;
  padding: 0 20px;

  @media screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 55px;
  }

  @media screen and (max-width: 450px) {
    padding: 0 10px;
    width: 100%;
  }
`;

// обертка ссылки
const LinkWrapper = styled.div`
  text-align: center;
`;

// ссылка с картинкой
const Link = styled.a`
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 8px;
  background-size: cover;
  background: url(${props => props.imgUrl}) center;
  height: 200px;
  width: 90%;
  max-width: 400px;
  background-size: cover;

  @media screen and (max-width: 900px) {
    max-width: 330px;
    height: 170px;
  }

  @media screen and (max-width: 600px) {
    height: 200px;
    max-width: 400px;
    width: 100%;
  }
`;

// затеняющая низ подложка под текст
const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(https://media.claustrophobia.com/static/master/img/landings/grad.png)
    0 100% repeat-x;
`;

// название квеста
const InnerText = styled.div`
  position: absolute;
  bottom: 17px;
  left: 0;
  width: 100%;
  text-align: left;

  & h5 {
    padding: 0 20px;
    margin-bottom: 3px;
  }
`;

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <SliderArrow to="prev" />,
  nextArrow: <SliderArrow to="next" />
};

const getSlidesToShow = windowWidth => {
  if (windowWidth > 1000) return 3;
  if (windowWidth > 600) return 2;
  return 1;
};

class Carousel extends React.Component {
  state = {
    quest_type: "",
    data: [],
    title: "",
    windowWidth: 0
  };

  componentDidMount = () => {
    const { questId } = this.props;
    const mode = this.props.mode ? this.props.mode : 'default';
    fetchRecommendedGames(questId, mode).then(myJson => this.setState({ ...this.state, ...myJson }))
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  renderThumbnail = q => {
    return (
      <Link
        href={ this.props.mode == 'franchisee' ? q.quest_page_url + 'info' : q.quest_page_url }
        imgUrl={q.hide_adult_content ? `${q.back_blur_url}` : `${q.back_unblur_url}`}
        key={q.id__link}
      >
        <Overlay>
          <InnerText>
            <h5>{q.name}</h5>
          </InnerText>
        </Overlay>
      </Link>
    );
  };

  render = () => {
    const { data, title, windowWidth } = this.state;
    const slidesAmount = Object.keys(data).length;
    const slidesToShow = getSlidesToShow(windowWidth);
    const settings = { ...sliderSettings, slidesToShow };
    const hasRecommended = !!Object.keys(data).length;
    const { infiniteLoop = true } = this.props;

    return hasRecommended ? (
      <RecommendWrapper>
        <RecommendContainer>
          <RecommendTitle>{title}</RecommendTitle>
          {slidesAmount < slidesToShow ? (
            <WrapperWithoutSlider>
              {data.map(q => this.renderThumbnail(q))}
            </WrapperWithoutSlider>
          ) : (
            <SliderWrapper>
              <Slider {...settings}>
                {data.map(q => (
                  <LinkWrapper key={q.id}>
                    {this.renderThumbnail(q)}
                  </LinkWrapper>
                ))}

                {/* если рекомендаций < 3, показываем все и нет петли
                    если === 3, дублируем для успшной реализации петли
                    если > 3, петя справляется сама */}

                {infiniteLoop &&
                  slidesAmount === slidesToShow &&
                  data.map(q => (
                    <LinkWrapper key={q.id}>
                      {this.renderThumbnail(q)}
                    </LinkWrapper>
                  ))}
              </Slider>
            </SliderWrapper>
          )}
        </RecommendContainer>
      </RecommendWrapper>
    ) : null;
  };
}

export default Carousel;
