import React, { Component } from "react";
// import "../../../sass/commonStyles.sass";
import "../../../sass/authFormStyles.sass";
import { sendPasswordRecoveryEmail } from "../../services";

export default class PassRecoveryForm extends Component {
  state = {
    email: "",
    response: {}
  };

  onCloseBtnClick = () => {
    this.props.onClose();
  };

  onFormSubmit = event => {
    event.preventDefault();
    sendPasswordRecoveryEmail({ email: this.state.email }).then(response =>
      this.setState({ response })
    );
  };

  onFormItemChange = value => {
    this.setState({ email: value });
  };

  changeCurrentStepTo = newForm => () => {
    this.props.changeCurrentStepTo(newForm);
  };

  render() {
    const { jsPhrases } = this.props;
    return (
      <div className="popup-pane auth-pane">
        <div className="popup-close-button" onClick={this.onCloseBtnClick} />
        <div className="form-title">
          <div className="form-title-icon lock" />
          <div className="form-title-text">
            {jsPhrases["popup.recovery_password.title"]}
          </div>
        </div>
        <p>{jsPhrases["popup.recovery_password.instruction"]}</p>

        {this.state.response.msg && (
          <div className={`response-error-block`}>
            {this.state.response.msg}
          </div>
        )}

        {(!Object.keys(this.state.response).length ||
          this.state.response.error) && (
          <form onSubmit={this.onFormSubmit} action="POST">
            <label className="input-label">
              <div>{jsPhrases["popup.recovery_password.email_or_phone"]}</div>
              <input
                type="text"
                className="form-text-input auth-text-input"
                placeholder={jsPhrases["popup.recovery_password.enter_email"]}
                onChange={event => this.onFormItemChange(event.target.value)}
                name="email"
                required
              />
            </label>
            <button className="form-button submit">
              {jsPhrases["popup.login.recovery_password"]}
            </button>
          </form>
        )}
        <div className="divided-blocks-wrapper">
          <div className="divided-block">
            <div className="accompanying-text">
              {jsPhrases["popup.recovery_password.log_in_note"]}
            </div>
            <div
              className="relocation-link with-arrow"
              onClick={this.changeCurrentStepTo("auth")}
            >
              {jsPhrases["popup.recovery_password.log_in"]}
            </div>
          </div>
          <div className="divided-block">
            <div className="accompanying-text">
              {jsPhrases["popup.recovery_password.not_registered_yet"]}
            </div>
            <div
              className="relocation-link with-arrow"
              onClick={this.changeCurrentStepTo("registration")}
            >
              {jsPhrases["popup.recovery_password.create_account"]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
