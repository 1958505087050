import React from "react";
import PropTypes from "prop-types";
import "../../../sass/bookingFormStyles.sass";

import BookingPane from "./BookingPane";
import PlayersForm from "./PlayersForm";
import PaymentForm from "./PaymentForm";
import AuthFormBlock from "../AuthFormBlock";
import PhoneVerificationForm from "../common/PhoneVerificationForm";
import EmailVerificationForm from "../common/EmailVerificationForm";
import Timer from "../common/Timer";

import { fetchUserInfo, fetchTimeslotData, book } from "../../services";
import {renderLockers} from '../../utils'
import ExtraPaymentForm from "./ExtraPaymentForm";

// const renderLockers = valueArray => {
//   return (
//     <div className="complexity-locks">
//       {valueArray.map((value, i) => (
//         <div className={`complexity-lock ${value}`} key={`locker-${i}`} />
//       ))}
//     </div>
//   );
// };

const renderGhosts = valueArray => {
  return (
    <div className="fear-level-ghosts">
      {valueArray.map((value, i) => (
        <div className={`fear-level-ghost ${value}`} key={`ghost-${i}`} />
      ))}
    </div>
  );
};

class BookingForm extends React.Component {
  state = {
    timeslotData: {},
    userInfo: {},
    bookingResponse: {},
    dataForBooking: {},
    game: {},
    currentStep: "players",
    userIsLoaded: false,
    timeslotIsLoaded: false
  };

  componentDidMount = () => {
    if (this.props.currentStep) {
      this.setState({ currentStep: this.props.currentStep });
    }
    this.getUserData();
    fetchTimeslotData(this.props.timeslotId).then(timeslotData => {
      this.setState({
        timeslotData,
      })

      // проверяем наличие несгоревшей game в ответе, если есть, то это забронированная этим пользователем игра,
      // положить game в booking response и перекинуть сразу на второй шаг
      // если нет, то все как обычно
      if (timeslotData.existing_game) {
        // проверяем если игра уже оплачена но нужна доплата
        if (timeslotData.existing_game.extra_price && timeslotData.existing_game.extra_price_payment === 'no'){
          this.setState({ game: timeslotData.existing_game }, () =>
            this.changeFormStep("extra_payment")
          );
        } else if (timeslotData.existing_game.payment === 'no') {  // обычная оплата
          this.setState({ game: timeslotData.existing_game }, () =>
            this.changeFormStep("payment")
          );
        }
      }

      this.setState({
        timeslotIsLoaded: true
      });

    });
  };

  getGame = () => {
    return this.state.game || this.state.bookingResponse.game
  };

  changeFormStep = newStep => this.setState({currentStep: newStep });

  getUserData = callback => {
    fetchUserInfo().then(userInfo => {
      this.setState({ userInfo, userIsLoaded: true });
      if (callback) callback(userInfo);
    });
  };

  onPlayersFormSubmit = data => {
    const {
      timeslotData: {
        gtm_params,
        price,
        quest: { id: questId }
      }
    } = this.state;
    const { currency_code: currencyCode } = gtm_params;

    book(data).then(res => {
      this.setState({ bookingResponse: res, game: res.game });
      if (!res.error) {
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode,
            add: { products: [{ ...gtm_params, quantity: 1 }] }
          }
        });

        // if (questId === 636) {
        //   // если Железяки - после брони редирект на страницу с инфой об оплате,
        //   // если любой другой квест - перевод на форму оплаты
        //   window.location = `/pay/select_payment_for_game/${res.game.id}/`;
        // } else
        if (!price) {
          //если price = 0, то это тестовая игра и после брони редирект без оплаты
          window.location = res.redirect_to;
        } else {
          this.changeFormStep("payment");
        }
      }
    });
  };

  onAnonymousPlayersFormSubmit = dataForBooking => {
    this.setState({dataForBooking});
    this.changeFormStep("auth");

  };

  getNumberWithCurrencySign = num => (
    <span

      dangerouslySetInnerHTML={{
        __html: num + "&nbsp" + this.state.timeslotData.currency_sign
      }}
    />
  );

  renderCurrentBookingStep = currentStep => {
    const { jsPhrases } = this.props;
    const {
      timeslotData,
      timeslotData: { quest },
      game,
      userInfo
    } = this.state;

    switch (currentStep) {
      case "payment":
        return (
          <PaymentForm
            key="payment-form"
            timeslotData={timeslotData}
            jsPhrases={jsPhrases}
            getNumberWithCurrencySign={this.getNumberWithCurrencySign}
            game={game}
            userInfo={userInfo}
            initialPayment={this.getInitialPayment(
              game.available_payment_backends
            )}
            mode='full'
          />
        );

      case "extra_payment":
        return (
          <ExtraPaymentForm
            key="extra-payment-form"
            timeslotData={timeslotData}
            jsPhrases={jsPhrases}
            getNumberWithCurrencySign={this.getNumberWithCurrencySign}
            game={game}
            userInfo={userInfo}
            initialPayment={this.getInitialPayment(
              game.available_payment_backends
            )}
            mode='extra'
          />
        );

      case "players":
        return (
          <PlayersForm
            key="players-form"
            timeslotData={timeslotData}
            onSubmit={this.onPlayersFormSubmit}
            onAnonymousSubmit={this.onAnonymousPlayersFormSubmit}
            jsPhrases={jsPhrases}
            getNumberWithCurrencySign={this.getNumberWithCurrencySign}
            initialPlayersCount={this.getInitialPlayersCount(quest)}
            userInfo={userInfo}
            initialLanguage={userInfo.language || "ru"}
          />
        );

      default:
        return "";
    }
  };

  renderStepsDisplay = currentStep => {
    const {
      timeslotData: {
        quest: { id: questId, quest_type: questType }
      }
    } = this.state;
    const { jsPhrases } = this.props;
    return (
      <React.Fragment>
        {currentStep !== 'extra_payment' &&
        <div className="steps-display-wrapper">
          <div className="steps-display-map">
            <div className="line" />
            <div className="dot-left" />
            <div className="dot-right" />
            <div className={`active-dot ${currentStep}`} />
          </div>
          <div className="steps-names-wrapper">
            <div
              className={`step-name players ${
                currentStep === "players" ? "active" : ""
              }`}
            >
              {/* Количество игроков */}
              {jsPhrases["popup.booking.players.players_amount"]}
            </div>
            <div
              className={`step-name payment ${
                currentStep === "payment" ? "active" : ""
              }`}
            >
              {/* Способ оплаты */}
              {questType === 'iron'
                ? // если Железяки, то текст "Промокод"
                  jsPhrases["popup.booking.payment.promo_placeholder"]
                : jsPhrases["popup.booking.players.payment_type"]}
            </div>
          </div>
        </div>
        }
      </React.Fragment>
    );
  };

  renderGameExtraInfoForEstonia = () => {
    const { jsPhrases } = this.props;
    const {
      quest: {
        country_name,
        city_name,
        address,
        city_id,
      }
    } = this.state.timeslotData;
    if (city_id === 13) {
      return (
        <div>
          <p className="franchise-info">
            {jsPhrases["popup.booking.company"]} <br/>
            {jsPhrases["popup.booking.reg_number"]} <br/>
            {jsPhrases["popup.booking.tax_number"]} <br/>
          </p>
          <div className="quest-address">{country_name}, {city_name}, {address}</div>
        </div>
      )
    } else {
      return (
        <div className="quest-address">{city_name}, {address}</div>
      )
    }
  }

  renderGameInfo = () => {
    const {
      dt: date,
      tm24: time,
      quest: {
        logo_url,
        name,
        complexity_in_locks,
        gamers_count,
        adult_content,
        available_languages,
        fear_level_in_ghosts,
        quest_type,
        // address,
        // city_name,
        // country_name,
        // city_id
      },
      userInfo,
    } = this.state.timeslotData;
    const langCode = this.state.userInfo.language;
    var dateString = date;
    if (langCode == 'et') {
      const regExp = /\d+/;
      const match = dateString.match(regExp);
      dateString = dateString.replace(match, match + '.')
    }
    const langArray = available_languages.map(l => l.code);
    return (
      <React.Fragment>
        <div>
          <img className="quest-logo" alt="quest-logo" src={logo_url} />
        </div>
        <div className="quest-title">{name}</div>
        {this.renderGameExtraInfoForEstonia()}
        <div className="quest-time">
          {dateString}, {time}
        </div>
        <div className="quest-info">
          {quest_type === "performance"
            ? renderGhosts(fear_level_in_ghosts)
            : renderLockers(complexity_in_locks)}
          <div className="players-count">{gamers_count}</div>
          <div className="adult-content">
            {adult_content ? `${adult_content}+` : ""}
          </div>
          <div>
            {langArray.map(l => (
              <div className="lang-tag" key={`lang-tag-${l}`}>
                {l}
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  getInitialPayment = availablePayments => {
    if (availablePayments.length === 0) {return 'payment-on-location'}
    return availablePayments[0][0];
  };

  getInitialPlayersCount = quest =>
    //для Железяк, Home Video и НИИ СЕКС по умолчанию минимум игроков
    //для Железяк - чтобы не щелкать с 72 до минимума,
    //для эротических - чтобы допуслуги сключались при введении большего числа, а не были включены по умолчанию
    [636, 639, 648, 737, 675, 742, 743, 676, 749, 777, 849, 417, 794, 757, 915, 793, 673, 802, 927, 935, 968].indexOf(quest.id) >= 0
    || ['iron', 'msk2048', 'online-team-quest', 'quiz'].includes(quest.quest_type)
      ? quest.min_gamers
      : this.state.timeslotData.available_tickets;

  updateInputCSRFTokenFromCookies = () => {
    function readCookie(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    }
    $('[name="csrfmiddlewaretoken"]').val(readCookie('phobia_csrf_token'));
  };

  authCallback = () => {
    const callback = userInfo => {
      if (!userInfo.anonymous && userInfo.phone)
        // We should update or CSRF tokens after every POST request:
        this.updateInputCSRFTokenFromCookies();
        this.onPlayersFormSubmit(this.state.dataForBooking);
    };
    this.getUserData(callback);
  };

  render() {

    const { jsPhrases } = this.props;
    const {
      currentStep,
      userIsLoaded,
      timeslotIsLoaded,
      timeslotData,
      userInfo
    } = this.state;

    const dataLoadingInProcess = !userIsLoaded || !timeslotIsLoaded;
    const bookingInProcess =
      currentStep === "auth" && !userInfo.anonymous && userInfo.phone && userInfo.is_trusted_email;
    const keyIsShown = dataLoadingInProcess || bookingInProcess;

    let isLockedForMe = this.getGame().timeslot === timeslotData.id
    if (timeslotData.id === undefined) {
      isLockedForMe = false
    }

    if (keyIsShown)
      return (
        <div className="popup-background">
          <div className="popup-content">
            <div className="popup-pane booking-pane">
              <div className="preloader-image" />
            </div>
          </div>
        </div>
      );

    if (currentStep === "auth" && userInfo.anonymous)
      return (
        <AuthFormBlock
          jsPhrases={jsPhrases}
          authCallback={this.authCallback}
          timeslotId={this.props.timeslotId}
          userInfo={userInfo}
        />
      );

    if (currentStep === "auth" && !userInfo.phone)
      return (
        <div className="popup-background">
          <div className="popup-content">
            <PhoneVerificationForm
              jsPhrases={jsPhrases}
              user_id={userInfo.id}
              onCodeSubmitCallback={this.authCallback}
              userInfo={userInfo}
            />
          </div>
        </div>
      );

    if (currentStep === "auth" && !userInfo.is_trusted_email)
    return (
      <div className="popup-background">
        <div className="popup-content">
          <EmailVerificationForm
            jsPhrases={jsPhrases}
            userId={userInfo.id}
          />
        </div>
      </div>
    );

    return (
      <BookingPane>
        <React.Fragment>
          {this.renderGameInfo()}
          {timeslotData.locked && !isLockedForMe ? (
            <p>{timeslotData.lock_text}</p>
          ) : (
            <React.Fragment>
              {this.state.bookingResponse.error && (
                <div>
                  <div className={`response-error-block`}>
                    {this.state.bookingResponse.user_msg_display}
                  </div>
                </div>
              )}
              {this.getGame().expires_in && currentStep !== 'extra_payment' && (
                  <React.Fragment>
                    <div className="booking-info">
                      {/* Игра предварительно забронирована */}
                      {jsPhrases["popup.booking.game_is_pre-booked"]}
                    </div>
                    <div className="booking-info">
                        <span>
                          {/* {`На оплату заказа`} */}
                          {[61, 526, 466, 190, 726,].indexOf(timeslotData.quest.id) >= 0 ?
                              jsPhrases["popup.booking.on_location_payment"] :
                              timeslotData.mode_prepay && timeslotData.mode_prepay.on_prepay ?
                                  jsPhrases["popup.booking.to_pay"] :
                                  jsPhrases["popup.booking.to_pay_no_pp"]}
                          &nbsp;
                          <Timer
                            secRemaining={
                              this.getGame().expires_in
                            }
                          /> {timeslotData.mode_prepay && timeslotData.mode_prepay.on_prepay ? '' : jsPhrases["popup.booking.minutes"]}
                        </span>
                    </div>
                  </React.Fragment>
                )}
              {this.renderStepsDisplay(currentStep)}
              {this.renderCurrentBookingStep(currentStep)}
            </React.Fragment>
          )}
        </React.Fragment>
      </BookingPane>
    );
  }
}

BookingForm.propTypes = {
  timeslotId: PropTypes.string.isRequired,
  jsPhrases: PropTypes.object.isRequired
};

export default BookingForm;
