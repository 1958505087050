import React, { Component } from "react";
import "../../../sass/bookingFormStyles.sass";
import PropTypes from "prop-types";
import CertificateForm from "./CertificateForm";
import {
  getYandexKassaToken,
  pay,
  applyPromocode
} from "../../services/payment";
import mastercardLogo from '../../../assets/mastercard_logo.png';
import visaLogo from '../../../assets/visa_logo.png';

export default class PaymentForm extends Component {
  state = {
    partialPrepay:
      this.props.timeslotData.mode_prepay && !this.props.timeslotData.mode_prepay.full_prepay,
    current_price: this.props.game.booking_price,
    prepay_price: this.props.game.prepay_price,
    paymentMethod: this.props.initialPayment,
    isPromoInputVisible: false,
    promocode: "",
    isPromocodeCheckLoading: false,
    promocodeResponse: {},
    formData: {},
    errors: {},
    isPaymentLoading: false,
    isKassaWidgetVisible: false,
    bookingAccept: !([13, 85].includes(this.props.timeslotData.quest.city_id)),
    available_payment_backends: this.props.available_payment_backends
  };

  componentDidMount() {
    this.addButtonCash(this.props.timeslotData)
  }
  addButtonCash = (timeslotData) => {
        const {
          jsPhrases,
        } = this.props;
        if ((timeslotData.mode_prepay && timeslotData.mode_prepay.show_cash_button_with_prepay) || (!timeslotData.mode_prepay)) {
          let add_cash_to_backends_payment = timeslotData.available_payment_backends.unshift(["payment-on-location", jsPhrases["popup.booking.payment.cash"]]);
          //this.state.paymentMethod = "payment-on-location"
          this.setState(() => {
            return {
              available_payment_backends: add_cash_to_backends_payment
            }
          })
        }            
      }

  renderPaymentButtons = (paymentTypesArray, chosenPaymentMethod) => {
    const {
      promocodeResponse: { result: promo_result },
    } = this.state;

    const {
      userInfo: { is_superuser: isSuperUser },
      timeslotData: { existing_game, qid  }
    } = this.props;

    return paymentTypesArray.map(p => (
      <div
        className={`booking-button payment-button ${
          chosenPaymentMethod === p[0] ? "pushed" : ""
        }${p[0] === "apple_pay" ? " hidden" : ""}`}
        key={`button-${p[0]}`}
        onClick={this.onChangePaymentMethod(p[0])}
      >
        {p[1]}
      </div>
    ));
  };


  onChangePaymentMethod = newMethod => {
    return () =>
      this.setState({
        paymentMethod: newMethod,
        errors: {},
        isPaymentLoading: false,
        formData: {
          cardCvc: "",
          cardNumber: ""
        },
        isKassaWidgetVisible: false
      });
  };

  onFormItemChange = ({ name, value }) => {
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [name]: value }
    });
  };

  renderPaymentFields = type => {
    const {
      jsPhrases,
      game,
      timeslotData: { currency_sign }
    } = this.props;
    const { isKassaWidgetVisible } = this.state;
    switch (type) {
      // embeeded здесь раньше была встроенная форма платежки яндекс денег
      // hash coomit 7b20504d711cff2c0053f528985ade55f713e3e9
      case "certificate":
        return (
          <CertificateForm
            jsPhrases={jsPhrases}
            gameId={game.id}
            bookingAccept={this.state.bookingAccept}
            analyticsDataPush={this.analyticsDataPush}
          />
        );


      case "payment-on-location":
        return (
          <React.Fragment>
            {this.state.errors.paymentError ? (
              <div className="response-error-block">
                {this.state.errors.paymentError}
              </div>
            ) : (
              ""
            )}
            <button
              className={`form-button submit booking-submit${
                this.state.isPaymentLoading ? " form-button-disabled" : ""
              }`}
              onClick={() => {
                this.onPaymentSubmit({ payment_method: type });
              }}
              disabled={!this.state.bookingAccept}
            >
              {/* Далее */}
              {jsPhrases["popup.booking.payment.next"]}
            </button>
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            {this.state.errors.paymentError ? (
              <div className="response-error-block">
                {this.state.errors.paymentError}
              </div>
            ) : (
              ""
            )}
            <button
              className={`form-button submit booking-submit${
                this.state.isPaymentLoading ? " form-button-disabled" : ""
              }`}
              onClick={() => {
                this.onPaymentSubmit({ payment_method: type });
              }}
              disabled={!this.state.bookingAccept}
            >
              {/* Оплатить */}
              {jsPhrases["popup.booking.payment.pay"]}
            </button>
          </React.Fragment>
        );
    }
  };

  onPaymentSubmit = data => {
    this.setState({ isPaymentLoading: true });
    this.pay(data).then(res => {
      if (res.result === "success") {
        this.analyticsDataPush();
        window.location = res.redirect_url;
      } else
        this.setState({
          isPaymentLoading: false,
          errors: { paymentError: res.msg }
        });
    });
  };

  pay = data => {
    return pay({
      game_id: this.props.game.id,
      partial_prepay: this.state.partialPrepay,
      mode: this.props.mode,
      ...data
    });
  };

  analyticsDataPush = () => {
    const { gtm_params } = this.props.timeslotData;
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: this.state.paymentMethod },
          products: [{ ...gtm_params, quantity: 1 }]
        }
      }
    });
  };

  applyPromocode = () => {
    this.setState({ isPromocodeCheckLoading: true });
    if (this.state.paymentMethod === "embedded") {
      document.getElementById("payment-form").innerHTML = "";
      this.setState({ isKassaWidgetVisible: false });
    }
    const data = {
      promocode: this.state.promocode,
      game_id: this.props.game.id
    };
    applyPromocode(data).then(res => {
      if (res.error !== true) {
        this.setState({
          current_price: res.resulting_price,
          prepay_price: res.prepay_price,
          promocodeResponse: res,
          isPromocodeCheckLoading: false,
        });
      } else {
        this.setState({
          promocodeResponse: res,
          isPromocodeCheckLoading: false,
        });
      }
      if (res.free_game) window.location = res.redirect_to;
    });
  };

  onPromocodeChange = promocode => {
    this.setState({ promocode, promocodeResponse: { user_msg_display: "" } });
  };

  togglePromoInputVisability = () => {
    const { isPromoInputVisible } = this.state;
    this.setState({ isPromoInputVisible: !isPromoInputVisible });
  };

  setPartialPrepay = value => {
    this.setState({ partialPrepay: value });
  };

  renderPromocodeBlock = () => {
    const {
      jsPhrases,
      game: { promo_code, mode }
    } = this.props;

    const {
      isPromoInputVisible,
      promocodeResponse: { user_msg_display, result },
      isPromocodeCheckLoading,
    } = this.state;

    //если промокод успешно применен только что, или применена Тройка, или в игре выбран режим - не показываем блок вообще.
    //если промокод успешно применен ранее юзером для этой игры - показываем строчку об этом
    if (result != "success" && !mode)
      return (
        <React.Fragment>
          {isPromoInputVisible ? (
            <React.Fragment>
              <label className="input-label promocode-booking-label">
                <input
                  className={`form-text-input form-promo`}
                  placeholder={
                    // `Промокод`
                    jsPhrases["popup.booking.payment.promo_placeholder"]
                  }
                  onChange={event => {
                    this.onPromocodeChange(event.target.value);
                  }}
                />
                {isPromocodeCheckLoading && (
                  <div className="preloader-wrapper promo-preloader-wrapper">
                    <div className="preloader-image promo-preloader" />
                  </div>
                )}
              </label>
              {user_msg_display && (
                <div className="promo-info promo-info-error">
                  {user_msg_display}
                </div>
              )}

              <div
                className={`form-button next-step booking-submit ${
                  isPromocodeCheckLoading ? "form-button-disabled" : ""
                }`}
                onClick={this.applyPromocode}
              >
                {/* Применить промокод */}
                {jsPhrases["popup.booking.payment.use_promocode"]}
              </div>
            </React.Fragment>
          ) : promo_code ? (
            <div>
              {/* Использован промокод */}
              {jsPhrases["popup.booking.payment.you_have_used_promocode"]}
              {/*{ user_msg_display != "" ? ` «${promo_code}»` : null }*/}
            </div>
          ) : (
            <div
              onClick={this.togglePromoInputVisability}
              className="text-hider"
            >
              {/* У меня есть промокод */}
              {jsPhrases["popup.booking.payment.i_have_promocode"]}
            </div>
          )}
        </React.Fragment>
      );
  };

  renderZhelezyakiForm = () => {
    const {
      jsPhrases,
      timeslotData: {
        quest: { phone: questPhone }
      }
    } = this.props;
    const { promocodeResponse } = this.state;
    return (
      <React.Fragment>
        <div className="text-block">
          {jsPhrases["popup.booking.payment.approval_text"]}{" "}
          <tel>{questPhone}</tel>
        </div>
        {this.renderPromocodeBlock()}
        {promocodeResponse.result && promocodeResponse.result === "success" ? (
          <div className="current-price-info">
            {/* Скидка по промокоду составила */}
            {promocodeResponse.msg}
          </div>
        ) : (
          ""
        )}
        {this.state.errors.paymentError ? (
          <div className="response-error-block">
            {this.state.errors.paymentError}
          </div>
        ) : (
          ""
        )}

        <button
          className={`form-button submit booking-submit${
            this.state.isPaymentLoading ? " form-button-disabled" : ""
          }`}
          onClick={() => {
            window.location = `/profile`;
          }}
          disabled={!this.state.bookingAccept}
        >
          {/* Подтвердить */}
          {jsPhrases["popup.booking.payment.approve"]}
        </button>
      </React.Fragment>
    );
  };

  handleBookingAccept = () => {
    // Меняет состояние bookingAccept
    this.setState({
      bookingAccept: !this.state.bookingAccept
    });
  };

  renderPaymentCardsLogo = () => {
    if (this.props.timeslotData.quest.city_id === 13) {
      return <div>
        <img className="payment-logo" src={mastercardLogo} alt=""/>
        <img className="payment-logo" src={visaLogo} alt=""/>
      </div>;
    }
  }


  returnBookingPhrase = () => {
    const {
      jsPhrases,
      timeslotData
    } = this.props;


    if (timeslotData.quest.city_id === 85) {
      /* Если Валенсия - Я прочитал и принимаю Общие условия бронирования и согласен
      с Политикой конфиденциальности и обработки персональных данных. */
      return (
        <React.Fragment>
          {/* Я прочитал и принимаю */}
          {jsPhrases["popup.booking.payment.i_read_and_accept"]}
          {" "}
          <a
            className="inline-link in-text"
            href="/es/CONDITIONS"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Общие условия бронирования */}
            {jsPhrases["popup.booking.payment.general_booking_conditions"]}
          </a>
          {" "}
          {/* и согласен с */}
          {jsPhrases["popup.booking.payment.and_agree_with"]}
          {" "}
          <a
            className="inline-link in-text"
            href="/es/aviso_legal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Политикой конфиденциальности и обработки персональных данных */}
            {jsPhrases["popup.booking.payment.privacy_policy_and_personal_data_processing"]}
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* Бронируя сеанс, вы принимаете */}
          {jsPhrases["popup.booking.payment.by_booking_you_are_accept"]}
          {" "}
          <a
            className="inline-link in-text"
            href="offer"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* оферту */}
            {jsPhrases["popup.booking.payment.an_offer"]}
          </a>
        </React.Fragment>
      );
    }
  };

  render() {
    const {
      jsPhrases,
      getNumberWithCurrencySign,
      timeslotData: {
        available_payment_backends,
        mode_prepay,
        qid: questId,
        quest: quest,
      },
      game: {
        prepay_price: game_prepay_price,
        booking_price: game_booking_price,
        additional_services_total_amount
      },
      mode,
    } = this.props;

    const {
      paymentMethod: chosenPaymentMethod,
      isPaymentLoading,
      partialPrepay,
      promocodeResponse: {
        resulting_price: promo_resulting_price,
        discount_amount: promo_discount_amount,
        prepay_price: promo_prepay_price,
        result: promo_result
      },
    } = this.state;

    // по умолчанию смотрим суммы в timeslot_booking response

    const currentPrice = partialPrepay ? this.state.prepay_price : this.state.current_price;
    // if (quest.quest_type === 'iron' && quest.id != 752 && quest.id != 658 && quest.id != 758 && quest.id != 789 && quest.id != 808 && quest.id != 874 && quest.id != 859 && quest.id != 919)
    //   return this.renderZhelezyakiForm();
    return (
      <React.Fragment>
        {mode_prepay && (
          <div className="prepay-wrapper">
            <div className="prepay-info">
              <div>
                {/* Варианты оплаты */}
                {jsPhrases["popup.booking.payment.payment_variants"]}
              </div>
              <div className="booking-option-accompanying-text">
                {/* Выберите вариант */}
                {jsPhrases["popup.booking.payment.choose_variant"]}
              </div>
            </div>
            {mode_prepay.full_prepay && (
              <div
                className={`booking-button prepay-button-100 ${ !partialPrepay ? "pushed" : "" }`}
                onClick={() => this.setPartialPrepay(false)}
              >
                {/* Полная оплата */}
                {jsPhrases["popup.booking.payment.full_price"]}
              </div>
            )}
            { mode_prepay.partial_prepay && (
              <div
                className={`booking-button prepay-button ${ partialPrepay ? "pushed" : "" }`}
                onClick={() => this.setPartialPrepay(true)}
              >
                {/* предоплата */}
                { mode_prepay.amount_partial_prepay ? getNumberWithCurrencySign(mode_prepay.amount_partial_prepay)
                : `${mode_prepay.percentage_partial_prepay}%` } {" "}
                {jsPhrases["popup.booking.payment.quarter_prepay"]}
              </div>
            )}
          </div>
        )}
        <div className="current-price">
          {/* К оплате */}
          {jsPhrases["popup.booking.payment.for_pay"]}{" "}
          {getNumberWithCurrencySign(currentPrice)}
        </div>
        { (
          <React.Fragment>
            <div className="current-price-info">
              {/* Доплата на месте наличными */}
              {jsPhrases["popup.booking.payment.cash"]}{" "}
              {getNumberWithCurrencySign(
                this.state.partialPrepay ? this.state.current_price - this.state.prepay_price + additional_services_total_amount :
                this.state.paymentMethod == "payment-on-location" ? this.state.current_price + additional_services_total_amount : additional_services_total_amount
              )}
            </div>
            {additional_services_total_amount ? (
              <div className="current-price-info">
                ({/*в том числе*/}
                
                { this.state.partialPrepay ?
                  (<span>
                    {jsPhrases["popup.booking.payment.include"]}{" "}
                    {getNumberWithCurrencySign(this.state.current_price - this.state.prepay_price)}{" "}
                    {jsPhrases["popup.booking.payment.for_game_and"]}
                  </span>) : ''
                }                          
                
                {/*за игру и*/}
                {}{" "}
                {getNumberWithCurrencySign(additional_services_total_amount)}{" "}
                {/*за дополнительные услуги*/}
                {jsPhrases["popup.booking.payment.for_additional_services"]})
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        ) }
        {promo_discount_amount ? (
          <div className="current-price-info">
            {/* Скидка по промокоду составила */}
            {jsPhrases["popup.booking.payment.promocode_discount_is"]}{" "}
            {getNumberWithCurrencySign(promo_discount_amount)}
          </div>
        ) : (
          ""
        )}
        {this.renderPromocodeBlock()}

        <div className="payment-type-wrapper">
          <div className="payment-info">
            <div>
              {/* Способ оплаты */}
              {jsPhrases["popup.booking.payment.payment_option"]}
            </div>
            <div className="booking-option-accompanying-text">
              {/* Выберите способ */}
              {jsPhrases["popup.booking.payment.choose_option"]}
            </div>
          </div>
          <div className="payment-buttons">
            {this.renderPaymentButtons(
              available_payment_backends,
              chosenPaymentMethod,
            )}
          </div>
        </div>

        {this.renderPaymentFields(chosenPaymentMethod)}

        {isPaymentLoading && (
          <div className="preloader-image payment-preloader" />
        )}

        <div className="offer-text">
          <label>
            <input
              type="checkbox"
              defaultChecked={this.state.bookingAccept}
              onChange={this.handleBookingAccept}
            />
          </label>
          {"  "}
          {this.returnBookingPhrase()}
          <br/>
          {/* Отменить игру можно не позднее, чем за 48 часов до ее начала. Чтобы произвести отмену, позвоните по телефону, указанному в карточке игры. */}
          {this.props.timeslotData.quest.city_id === 13 ? <div className="return-condition">{jsPhrases["popup.booking.payment.return_condition"]}</div> : null}
        </div>
        {this.renderPaymentCardsLogo()}
      </React.Fragment>
    );
  }
}

PaymentForm.propTypes = {
  timeslotData: PropTypes.object,
  userInfo: PropTypes.object,
  getNumberWithCurrencySign: PropTypes.func.isRequired,
  jsPhrases: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  initialPayment: PropTypes.string.isRequired
};
