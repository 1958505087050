import React, {Component} from "react";
// import PropTypes from "prop-types";

import {getUserBanners} from "../../services";

import "./TopBannerBlock.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollerBlock from "../ScrollerBlock";


class PromoBanner extends Component {

  sendAnalyticsSignal(banner, eventType = false) {

    const { fbq, dataLayer } = window;

    const promotion = {
      'name': banner.slug,
      'creative': banner.slug,
      'position': 'top'
    };

    const clickEvent = {
      'event': 'promotionClick',
      'ecommerce': {
        'promoClick': {
          'promotions': [promotion,]
        }
      }
    };

    const viewEvent = {
        'ecommerce': {
            'promoView': {
                'promotions': [promotion,]
            }
        }
    };
    // dataLayer.push(event);
  }

  render() {
    const { banner } = this.props;

    let className = 'top-promo-banner';

    let viewportWidth= Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    let _isMobileHQEnabled = (banner.hq_mobile_image !== '' && (window.devicePixelRatio||1) > 1);

    let selectedBannerImageURL = (viewportWidth > 800) ? banner.desktop_image :
      ((_isMobileHQEnabled) ? (banner.hq_mobile_image) : (banner.mobile_image));

    let styles = {
      backgroundImage: `url(${selectedBannerImageURL})`,
    };

    if (_isMobileHQEnabled && !viewportWidth < 800) {
      className += " hq"
    }

    this.sendAnalyticsSignal(banner);

    return (
      <a href={banner.url} target={banner.new_tab ? 'blank': ''} className={className} style={styles}></a>
    )
  }
}

class TopBannerBlock extends Component {

  state = {
    current: false,
    userBannersList: false,
  };

  componentDidMount() {
    let bannerRequestReferrerPath = window.location.pathname;
    getUserBanners(bannerRequestReferrerPath).then(userBannersList => this.sortUserBannersList(userBannersList))
  }

  sortUserBannersList(userBannersList) {
    function _tryBannerSlug(slug) {
        return Math.random() >= 0.5;
      }
    if (userBannersList.length > 0) {
      let sortedUserBannersList = userBannersList.sort(function(a, b){
        let x=_tryBannerSlug(a.slug);
        let y=_tryBannerSlug(b.slug);
        return (x === y)? 0 : x? -1 : 1;
      });
      this.setState({userBannersList});
      this.forceUpdate();
    }
    return false;
  }

  render() {
    let {userBannersList} = this.state;

    if (!userBannersList) {
        return null
    } else {
      let elements = userBannersList.map(banner => (
        <PromoBanner banner={banner} key={banner.id}/>
      ));
      return (
        <div className="top-banner-block">
          <ScrollerBlock elements={elements}>

          </ScrollerBlock>
        </div>
      )
    }
  }
}

// TopBannerBlock.propTypes = {
//   // jsPhrases: PropTypes.object.isRequired
// };

export default TopBannerBlock;
