import PaymentForm from "./PaymentForm";
import PropTypes from "prop-types";
import React from "react";

export default class ExtraPaymentForm extends PaymentForm {
  render() {
    const {
      jsPhrases,
      getNumberWithCurrencySign,
      timeslotData: {
        available_payment_backends,
        mode_prepay,
        qid: questId,
        quest: quest,
      },
      game: {
        prepay_price: game_prepay_price,
        booking_price: game_booking_price,
        additional_services_total_amount,
        extra_price: extra_price,
      },
      mode,
    } = this.props;

    const {
      paymentMethod: chosenPaymentMethod,
      isPaymentLoading,
      partialPrepay,
      promocodeResponse: {
        resulting_price: promo_resulting_price,
        discount_amount: promo_discount_amount,
        prepay_price: promo_prepay_price,
        result: promo_result
      },
    } = this.state;



    const currentPrice = extra_price;
    return (
      <React.Fragment>
        {mode_prepay && (
          <div className="prepay-wrapper">
            <div className="prepay-info">
              <div>
                {/* Варианты оплаты */}
                {jsPhrases["popup.booking.payment.payment_variants"]}
              </div>
              <div className="booking-option-accompanying-text">
                {/* Выберите вариант */}
                {jsPhrases["popup.booking.payment.choose_variant"]}
              </div>
            </div>
            <div
                className={`booking-button prepay-button-100 ${
                  "pushed"
                }`}
              >
                {/* Доплата */}
                {jsPhrases["popup.booking.payment.extra_price"]}
              </div>
          </div>
        )}
        <div className="current-price">
          {/* К доплате */}
          {jsPhrases["crm.fields.to_pay_amount"]}{" "}
          {getNumberWithCurrencySign(currentPrice)}
        </div>

        <div className="payment-type-wrapper">
          <div className="payment-info">
            <div>
              {/* Способ оплаты */}
              {jsPhrases["popup.booking.payment.payment_option"]}
            </div>
            <div className="booking-option-accompanying-text">
              {/* Выберите способ */}
              {jsPhrases["popup.booking.payment.choose_option"]}
            </div>
          </div>
          <div className="payment-buttons">
            {this.renderPaymentButtons(
              available_payment_backends.filter(x => ['kassa', 'YANDEX_AC'].includes(x[0])),
              chosenPaymentMethod
            )}
          </div>
        </div>

        {this.renderPaymentFields(chosenPaymentMethod)}

        {isPaymentLoading && (
          <div className="preloader-image payment-preloader" />
        )}
      </React.Fragment>
    );
  }

}


ExtraPaymentForm.propTypes = {
  timeslotData: PropTypes.object,
  userInfo: PropTypes.object,
  getNumberWithCurrencySign: PropTypes.func.isRequired,
  jsPhrases: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired,
  initialPayment: PropTypes.string.isRequired
};
