// import React from "react";
import styled from "styled-components";
import img from "../../../assets/controls.png";


const bgPositionData = {
  prev: `background-position: 0 0 ; 
  left: 25px ;`,
  prevHover: `background-position: 0 -63px;`,
  next: `background-position: 0 -126px ; 
  right: 25px ;`,
  nextHover: `background-position: 0 -189px ;`
};

const SliderArrow = styled.div`
  &&& {
    width: 31px ;
    height: 63px ;
    background-image: url(${img}) ;
    z-index: 3;

    ${({ to }) => bgPositionData[to]}

    // для перекрытия стилей пакета slick

    &:before {
      content: "" ;
    }

    &:hover {
      ${({ to }) => bgPositionData[to + "Hover"]}
    }
  }
`;

export default SliderArrow;
