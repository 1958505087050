import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import "../../../sass/authFormStyles.sass";
import "../../../sass/bookingFormStyles.sass";

import {sendEmailVerificationRequest} from "../../services";

export default class EmailVerificationForm extends Component {
  state = {
    enteredEmail: null,
    emailIsPreValidated: false,
    emailConfirmRequestSent: false,
    emailConfirmResponseMessage: null,
    error: false,
  };

  constructor(props) {
    super(props);

    this.requestEmailConfirmation = this.requestEmailConfirmation.bind(this);
    this.renderEmailConfirmationResponse = this.renderEmailConfirmationResponse.bind(this);
  };

  onEmailChange(enteredEmail) {

    this.setState({enteredEmail: enteredEmail});

    this.validateEmail(enteredEmail)

  };

  validateEmail(email) {
    let isValidated = false
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    isValidated = re.test(String(email).toLowerCase());

    this.setState({emailIsPreValidated: isValidated});

  };

  requestEmailConfirmation() {
    let {enteredEmail, emailIsPreValidated} = this.state
    const {userId} = this.props

    if (emailIsPreValidated) {

      sendEmailVerificationRequest(userId, enteredEmail).then(response => {

        let responseMessage

        if (response.errors) {
          this.setState({error: true});
          responseMessage = response.errors.email
        } else {
          responseMessage = response.msg
        }

        this.setState({emailConfirmRequestSent: true});
        this.setState({emailConfirmResponseMessage: responseMessage});

      });
    }

  };

  renderEmailConfirmationResponse(emailConfirmResponseMessage) {
    return (
      <p dangerouslySetInnerHTML={{__html: emailConfirmResponseMessage}}></p>
    )
  }

  renderEmailConfirmationRequest() {
    const {jsPhrases} = this.props

    let {emailIsPreValidated} = this.state

    return (
      <React.Fragment>
        <input
          type="email"
          className="form-text-input auth-text-input  "
          placeholder={jsPhrases["popup.registry.enter_email"]}
          onChange={event => this.onEmailChange(event.target.value)}
          required
        />
        {emailIsPreValidated
          ? null
          : <span className="input-annotation">{jsPhrases["popup.email_verification.email_invalid"]}</span>
        }

        <button
          className="form-button submit"
          onClick={this.requestEmailConfirmation}
          disabled={!emailIsPreValidated}>
          {jsPhrases["popup.email_verification.next"]}
        </button>


      </React.Fragment>
    )
  };

  onCloseBtnClick = () => {
    window.closeEmailVerificationForm = true
    ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode);
    this.onClose;
  };

  render() {
    let {emailConfirmResponseMessage, emailConfirmRequestSent} = this.state

    return (
      <div className="popup-pane auth-pane">
        <div className="popup-close-button" onClick={this.onCloseBtnClick}/>
        {/*<div>Email</div>*/}
        <h3 className="auth-pane-title">Email</h3>
        {emailConfirmRequestSent ? this.renderEmailConfirmationResponse(emailConfirmResponseMessage) : this.renderEmailConfirmationRequest()}
      </div>
    );
  }
}

EmailVerificationForm.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  jsPhrases: PropTypes.object.isRequired
};
