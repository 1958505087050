import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactPhoneInput from "react-phone-input-2";

import "../../../sass/authFormStyles.sass";
import "../../../sass/bookingFormStyles.sass";
import { confirmPhone, sendPhone } from "../../services";

export default class PhoneVerificationForm extends Component {
  state = {
    currentStep: "phone",
    phone: "",
    code: "",
    error: ""
  };

  componentDidMount = () => {
    if (this.props.phone)
      this.setState({ phone: this.props.phone, currentStep: "code" });
  };

  onItemChange = target => {
    this.setState({
      [target.name]: target.value
    });
  };

  onPhoneStepSubmit = event => {
    event.preventDefault();
    sendPhone({ user: this.props.user_id, phone: this.state.phone }).then(
      res => {
        if (res.success) this.setState({ currentStep: "code", error: "" });
        else this.setState({ error: res.error });
      }
    );
  };

  onCodeStepSubmit = () => {
    const data = { user_id: this.props.user_id, sms_code: this.state.code };
    confirmPhone(data).then(response => {
      if (response.status === "success") {
        window.dataLayer.push({ event: "RegStep2" });
        if (this.props.onCodeSubmitCallback) this.props.onCodeSubmitCallback();
      } else {
        this.setState({ error: response.error })
      }
    });
  };

  onPhoneChange = (value, data) => {
    // сохраняем введенное в поле значение
    this.setState({ phone: value });
    if (Object.keys(data).length) {
      const phone_cc = data.dialCode;
      this.setState({ phone_cc });
    }
  };

  onChangePhoneClick = () => {
    this.setState({
      phone: "",
      phone_cc: "",
      error: "",
      currentStep: "phone",
      code: ""
    });
  };

  renderPhoneForm = () => {
    const {
      jsPhrases,
      userInfo: { country_code }
    } = this.props;
    const { error } = this.state;
    return (
      <React.Fragment>
        <p>{jsPhrases["popup.phone_verification.phone_number"]}</p>
        <form
          onSubmit={this.onPhoneStepSubmit}
          action="POST"
          className="phone-verification-form"
        >
          <label className="input-label">
            <ReactPhoneInput
              preferredCountries={["ru"]}
              value={this.state.phone}
              defaultCountry={country_code}
              enableSearchField={true}
              countryCodeEditable={true}
              onChange={(value, data) => this.onPhoneChange(value, data)}
              placeholder={jsPhrases["popup.registry.type_phone"]}
              inputExtraProps={{
                name: "reg_phone",
                required: true,
                minLength: 4
              }}
              inputClass="phone-input"
              dropdownClass="phone-dropdown"
            />
          </label>
          {error ? <div className="response-error-block">{error}</div> : ""}
          <button
            className="form-button next-step"
            onClick={this.onPhoneStepSubmit}
          >
            {jsPhrases["popup.phone_verification.next"]}
          </button>
        </form>
      </React.Fragment>
    );
  };

  renderVerificationCodeForm = () => {
    const { jsPhrases } = this.props;
    return (
      <React.Fragment>
        <p>
          {jsPhrases["popup.phone_verification.enter_code"]} {this.state.phone}
        </p>
        {this.state.error && (
          <div className={`response-error-block`}>{this.state.error}</div>
        )}
        <label className="input-label">
          <input
            className="form-text-input"
            onChange={event => this.onItemChange(event.target)}
            name="code"
            placeholder={jsPhrases["popup.phone_verification.type_code"]}
          />
        </label>
        <div className="form-button submit" onClick={this.onCodeStepSubmit}>
          {jsPhrases["popup.phone_verification.next"]}
        </div>
        <div className="relocation-link" onClick={this.onChangePhoneClick}>
          {jsPhrases["popup.phone_verification.change_phone_number"]}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="popup-pane auth-pane">
        <div className="popup-close-button" onClick={this.onClose} />
        {this.state.currentStep === "phone"
          ? this.renderPhoneForm()
          : this.renderVerificationCodeForm()}
      </div>
    );
  }
}

PhoneVerificationForm.propTypes = {
  phone: PropTypes.string,
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCodeSubmitCallback: PropTypes.func,
  userInfo: PropTypes.object.isRequired
};
