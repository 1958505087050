import {getCsrf} from "./csrf";


export function fetchTicketTypesData(questId) {
  return fetch(`/api/tickets/?quest=${questId}`, {
    cache: "no-store",
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer"
  }).then(response => response.json());
}

export function postTicketsData(data) {
  return fetch(`/pay/api/tickets/order/`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response.json());
}

export function postPromocode(promocode) {
  return fetch(`/promo/api/check-promo-code-arcade/?promo_code=${promocode}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrf()
    },
    redirect: "follow",
    referrer: "no-referrer",
  }).then(response => response.json()); 
}
